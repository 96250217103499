import styles from "./Likes.module.css";
// import tutor from '../../images/teacher.jpg';
import { useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";

export default function Likes() {
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <main className={styles.main}>
        <div className={styles.info}>
          <section className={styles.css_1xw6rpm}>
            {/* 상단 타이틀 */}
            <div className={styles.css_1ixgpnt}>
              <h2 className={styles.css_1elspdf}>나의 찜 목록</h2>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
