import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import NoContent from "../../components/NoContent";

export default function Daily() {
  return (
    <>
      <Helmet>
        <title>일상 - 류용택(Kyle Ryu)</title>
      </Helmet>
      <Nav />
      <NoContent />
      <Footer />
    </>
  );
}
