import { useState, useEffect } from 'react'
import { appAuth } from '../firebase/config'
import { signInWithEmailAndPassword, getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom'

// 구글 로그인
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export const useLogin = () => {

  // [로그인 지속 코드]
  // 최초 인스턴스화된 후에 appAuth 객체에 지속성 설정
  useEffect(() => {
    const auth = getAuth()
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // 성능과 메모리를 위해 반드시 새로운 useEffect를 사용해야 합니다.
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { user, dispatch } = useAuthContext();
  const login = (email, password) => {
    setError(null); // 아직 에러가 없으니 null 입니다.
    setIsPending(true); // 통신중이므로 true입니다.

    signInWithEmailAndPassword(appAuth, email, password)
      .then((userCredential) => {

        // Signed in
        const user = userCredential.user;
        dispatch({ type: 'login', payload: user });
        setError(null);
        setIsPending(false);

        alert(user.displayName + '님의 방문을 환영합니다!');
        navigate('/');

        // 회원 정보를 정상적으로 받지 못하면 실패입니다.
        if (!user) {
          throw new Error('로그인에 실패했습니다.');
        }
      })
      .catch((err) => {
        setError(err.message);
        setIsPending(false);
        console.log(err.message);
      });
  }

  // 구글 로그인
  function loginWithGoogle() {
    const provider = new GoogleAuthProvider(); // provider 구글 설정
    signInWithPopup(appAuth, provider) // 팝업창 띄워서 로그인
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch({ type: 'login', payload: user });
        setError(null);
        setIsPending(false);

        setTimeout(() => {
          alert(`${user.displayName} 님의 방문을 환영합니다!`);
          navigate('/');
        }, 100); // 0.1초 딜레이 후에 alert를 띄웁니다.
      })
      .catch((err) => {
        setError(err.message);
        setIsPending(false);

        setTimeout(() => {
          alert('접속 실패');
        }, 100);
      });
  }


  return { error, isPending, login, loginWithGoogle }
}