import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>개인정보 처리방침 - 프로과외</title>
      </Helmet>
      <Nav />
      <div>개인정보 처리방침</div>
      <Footer />
    </>
  )
}