import logo from "../images/logo.svg";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  let navigate = useNavigate();

  return (
    <footer>
      <div className={styles.footer_top}></div>
      <div className={styles.css_8t0ewa}>
        <div className={styles.css_192tn2r}>
          <div className={styles.css_120gsus}>
            <a
              aria-label="홈"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                alt="로고"
                src={logo}
                className="cursor-pointer h-24pxr w-96pxr"
              />
            </a>
          </div>
          <div className={styles.css_sx764e}>
            <div className={styles.css_szycpx}>
              <a href="https://prolisten.net" target="_blank" rel="noreferrer">
                프로리슨
              </a>
              <a
                href="https://soloventures.net"
                target="_blank"
                rel="noreferrer"
              >
                솔로벤처스
              </a>
              <a
                href="https://www.linkedin.com/in/kyle-ryu-381b5b227"
                target="_blank"
                rel="noreferrer"
              >
                링크드인
              </a>
              {/* <a onClick={() => { navigate('/user-terms') }} className="cursor-pointer" target="_blank" rel="noreferrer">이용약관</a>
              <a onClick={() => { navigate('/privacy-policy') }} className="cursor-pointer" target="_blank" rel="noreferrer">개인정보
                처리방침</a> */}
            </div>
          </div>
        </div>
        <div className={styles.css_b3uwyt}>
          <div className="mb-2">
            프로리슨 | 대표 : 류용택 | 개인정보보호책임자 : 류용택
            <br />
            사업자등록번호 : 568-02-02454 | 통신판매업신고 : 2024-서울서초-1635
            <br />
            문의 : kyle@prolisten.net
            {/* <br />
            경기도 성남시 분당구 대왕판교로 645번길 12 경기창조경제혁신센터
            7,9층 130호 */}
          </div>
          ©KyleRyu. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
