import {
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Business from "./pages/Business/Business";
import Post from "./pages/Business/Post/Post";
import Post2 from "./pages/Business/Post2/Post2";
import Post3 from "./pages/Business/Post3/Post";
import DevPost from "./pages/Develop/DevPost/Post";
import Develop from "./pages/Develop/Develop";
import Daily from "./pages/Daily/Daily";
import Service from "./pages/Rules/Service";
import Privacy from "./pages/Rules/Privacy";
import Account from "./pages/MyPage/Account";
import Profile from "./pages/MyPage/Profile";
import Likes from "./pages/Likes/Likes";
import TutorResume from "./pages/Resume/TutorResume";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import MetaTag from "./components/MetaTag";

// 채널톡
import ChannelService from "./hooks/ChannelService";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // 채널톡 [start]
  const hiddenPages = ["/login", "/signup"]; // 숨기고 싶은 페이지 주소 추가
  const shouldHideButton = hiddenPages.includes(location.pathname);

  const hideChannelButton = () => ChannelService.hideChannelButton();
  const showChannelButton = () => ChannelService.showChannelButton();

  const updateChannelButtonVisibility = () => {
    const scrolledDown = window.scrollY >= 20;

    if (shouldHideButton || scrolledDown) {
      hideChannelButton();
    } else {
      showChannelButton();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateChannelButtonVisibility);

    // 채널톡 설치 및 부팅
    ChannelService.loadScript();
    ChannelService.boot({
      pluginKey: "95e019cc-243d-4b0a-b20f-4d02f5fe131c",
      hideChannelButtonOnBoot: shouldHideButton, // 여러 개의 주소에 따라 버튼 숨김 여부 설정
    });

    return () =>
      window.removeEventListener("scroll", updateChannelButtonVisibility);
  }, [location.pathname]); // 조건 변경을 감지하기 위해 location.pathname 의존성 추가
  // //채널톡 [End]

  // Next Page 최상단으로 이동, Current Page 스크롤 위치 유지
  useEffect(() => {
    const scrollY = window.scrollY;
    return () => {
      window.scrollTo(0, scrollY);
    };
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/signup" element={<Signup></Signup>}></Route>
        <Route path="/business" element={<Business></Business>}></Route>
        <Route path="/business/1" element={<Post></Post>}></Route>
        <Route path="/business/2" element={<Post2></Post2>}></Route>
        <Route path="/business/3" element={<Post3></Post3>}></Route>

        <Route path="/develop" element={<Develop></Develop>}></Route>
        <Route path="/develop/1" element={<DevPost></DevPost>}></Route>

        <Route path="/daily" element={<Daily />}></Route>
        <Route path="/profile" element={<Profile></Profile>}></Route>
        <Route path="/account" element={<Account></Account>}></Route>
        <Route path="/likes" element={<Likes></Likes>}></Route>
        <Route path="/tutorresume" element={<TutorResume />}></Route>

        {/* 이용약관, 개인정보처리방침 */}
        <Route path="/user-terms" element={<Service />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>

        {/* 404 페이지 */}
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
