import styles from "./PostList.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import ViewAll from "./ViewAll";

export default function PostList() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className={styles.info}>
        <section className={styles.css_1xw6rpm}>
          {/* 상단 타이틀 */}
          <div className={styles.css_1ixgpnt}>
            <h2 className={styles.css_1elspdf}>사업</h2>
            {/* 전체보기 */}
            {/* <div className={styles.css_fl7us7}>
              <a>
                <div className={styles.css_p1vanc}>
                  <p
                    className={styles.css_byjqmz}
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    전체보기
                  </p>
                  <span>
                    <div className={styles.css_1gvano}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="#535961"
                          d="M9.33 4.83c.439-.44 1.151-.44 1.59 0l6.375 6.374c.44.44.44 1.152 0 1.591L10.92 19.17a1.125 1.125 0 1 1-1.59-1.59L14.909 12l-5.58-5.58a1.125 1.125 0 0 1 0-1.59z"
                        ></path>
                      </svg>
                    </div>
                  </span>
                </div>
              </a>
            </div> */}
            <ViewAll />
          </div>

          <ul className={styles.css_1nwykk}>
            {/* 포스팅 목록 => li */}
            {/* 100억 */}
            <li
              onClick={() => {
                navigate("/business/3");
              }}
            >
              <article className={styles.css_1pr9edg}>
                <a>
                  <figure className={styles.css_1gd0osy}>
                    <img
                      alt="썸네일"
                      sizes="100vw"
                      src="https://firebasestorage.googleapis.com/v0/b/kyleryu-blog.appspot.com/o/home%2Fmoney.webp?alt=media&token=51cbac98-32a6-444d-80e4-dd7f7241e4fc"
                      decoding="async"
                      data-nimg="future-fill"
                      loading="lazy"
                    />
                  </figure>
                  <div className={styles.wrapBox}>
                    <div>
                      <div role="button" tabindex="0">
                        <h3
                          className={[styles.userName, styles.css_1to8sx0].join(
                            " "
                          )}
                        >
                          개발자가 100억을 버는 가장 빠른 방법
                        </h3>
                      </div>
                    </div>
                    <div className={styles.css_1rtzn1u}>
                      <span>
                        <p className={styles.css_oz575p}>2024년 03월 18일</p>
                      </span>
                    </div>
                  </div>
                  <div className={styles.css_16nb092}></div>
                </a>
              </article>
            </li>
            {/* 스타트업 */}
            <li
              onClick={() => {
                navigate("/business/2");
              }}
            >
              <article className={styles.css_1pr9edg}>
                <a>
                  <figure className={styles.css_1gd0osy}>
                    <img
                      alt="썸네일"
                      sizes="100vw"
                      src="https://firebasestorage.googleapis.com/v0/b/kyleryu-blog.appspot.com/o/home%2Fdeliver.webp?alt=media&token=b1dcef22-e2db-4030-b172-53616e3114b9"
                      decoding="async"
                      data-nimg="future-fill"
                      loading="lazy"
                    />
                  </figure>
                  <div className={styles.wrapBox}>
                    <div>
                      <div role="button" tabindex="0">
                        <h3
                          className={[styles.userName, styles.css_1to8sx0].join(
                            " "
                          )}
                        >
                          영재 소년, 택배 상하차를 뛰다.
                        </h3>
                      </div>
                    </div>
                    <div className={styles.css_1rtzn1u}>
                      <span>
                        <p className={styles.css_oz575p}>2023년 11월 21일</p>
                      </span>
                    </div>
                  </div>
                  <div className={styles.css_16nb092}></div>
                </a>
              </article>
            </li>
            {/* 판교 */}
            <li
              onClick={() => {
                navigate("/business/1");
              }}
            >
              <article className={styles.css_1pr9edg}>
                <a>
                  <figure className={styles.css_1gd0osy}>
                    <img
                      alt="썸네일"
                      sizes="100vw"
                      src="https://firebasestorage.googleapis.com/v0/b/kyleryu-blog.appspot.com/o/home%2Fdeveloper.webp?alt=media&token=74347613-4326-459d-a264-2967a7401607"
                      decoding="async"
                      data-nimg="future-fill"
                      loading="lazy"
                    />
                  </figure>
                  <div className={styles.wrapBox}>
                    <div>
                      <div role="button" tabindex="0">
                        <h3
                          className={[styles.userName, styles.css_1to8sx0].join(
                            " "
                          )}
                        >
                          10살 꼬마 개발자, 판교 스타트업 대표가 되다.
                        </h3>
                      </div>
                    </div>
                    <div className={styles.css_1rtzn1u}>
                      <span>
                        <p className={styles.css_oz575p}>2023년 11월 08일</p>
                      </span>
                    </div>
                  </div>
                  <div className={styles.css_16nb092}></div>
                </a>
              </article>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}
