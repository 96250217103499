import { useState } from 'react';
import { appAuth } from '../firebase/config';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {
  // 에러 정보를 저장합니다.
  const [error, setError] = useState(null);
  // 현재 서버와 통신 상태를 저장합니다.
  const [isPending, SetIsPending] = useState(false);

  const { dispatch } = useAuthContext();

  const navigate = useNavigate();

  const signup = (email, password, displayName) => {
    setError(null); // 아직 에러가 없습니다.
    SetIsPending(true); // 통신을 진행중입니다.

    createUserWithEmailAndPassword(appAuth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        alert('가입을 환영합니다!');
        navigate('/');

        if (!user) {
          throw new Error('회원가입에 실패했습니다.');
        }

        updateProfile(appAuth.currentUser, { displayName })
          .then(() => {
            dispatch({ type: 'login', payload: user });
            setError(null);
            SetIsPending(false);
          }).catch((err) => {
            setError(err.message);
            SetIsPending(false);
          })

      }).catch((err) => {
        setError(err.message);
        SetIsPending(false);
      })
  }
  return { error, isPending, signup }
}