import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import PostList from "../../components/PostList";
import styles from "./Business.module.css";

export default function Business() {
  return (
    <>
      <Helmet>
        <title>사업 - 류용택(Kyle Ryu)</title>
      </Helmet>
      <Nav />
      <PostList />
      <Footer />
    </>
  );
}
