import Nav from '../../components/Nav';
import './TutorResume.css';
import styled from 'styled-components';

let Main = styled.div`
  margin-top: 0;
  background-color: #fff;
  text-align: left;
  
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

export default function TutorResume() {

  return (
    <>
      <Nav />
      <Main>
        <article className="css_vg6we9">
          <h1 className="css_1tnomb9">이력서 작성</h1>
          <form>
            <header id="basic-section" className="css_1k9ek97">
              <h2 className="css_8mcuh1">기본 정보</h2>
              <div className="css_l7llpu">
                <div className="left css_cvvyv5">
                  <section className="css_133pvyo">
                    <input type="text"
                      id="input-name" name="name" maxlength="20" placeholder="이름을 입력해 주세요." className="css_1qvqm7m" />
                  </section>
                  <section className="css_m16sm3">
                    <input type="text"
                      id="input-job" name="job" maxlength="80" placeholder="학교 또는 회사를 입력해 주세요." className="css_1qvqm7m" />
                  </section>
                  <div className="css_1chys3l">
                    <div className="dropdown css_19uskpi"><input type="text" name="phoneNumber" data-testid="inputPhone"
                      className="css_14ly3ew" />
                      <div className="css_79elbk"><span className="css_817y00"><span>휴대폰 번호</span><span
                        className="css_mdh6b">*</span></span>
                        <div className="css_1qnlkji">
                          <div className="css_yiolqq"><span data-testid="codePhone" className="css_koyv6e">+82</span>
                            <div className="rallit-icon icon-wrapper css_12r39mb"><svg width="18" height="18" fill="none"
                              viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.378 6.997c.33.33.33.864 0 1.193l-4.781 4.782a.844.844 0 0 1-1.194 0L3.623 8.19a.844.844 0 1 1 1.192-1.193L9 11.182l4.185-4.185a.844.844 0 0 1 1.193 0z"
                                fill="#535961"></path>
                            </svg></div>
                          </div><input type="tel" data-testid="numberPhone" placeholder="01012345678" maxlength="20" min="0"
                            className="css_jpk05f" value="" />
                        </div>
                      </div>
                      <div className="css_fiamws">
                        <div className="rallit-icon css_12r39mb"><svg width="12" height="12" fill="none" viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.686 2.686a.635.635 0 0 1 .897 0L8 7.103l4.417-4.417a.635.635 0 0 1 .897.897L8.897 8l4.417 4.417a.635.635 0 1 1-.897.897L8 8.897l-4.417 4.417a.635.635 0 1 1-.897-.897L7.103 8 2.686 3.583a.635.635 0 0 1 0-.897z"
                            fill="#e50000"></path>
                        </svg></div><span className="css_ce81wa">휴대폰 번호를 정확히 입력해 주세요.</span>
                      </div>
                    </div>
                    <div data-error="false" className="css_166vh9w"><label><span className="css_817y00"><span>이메일</span><span
                      className="css_mdh6b">*</span></span>
                      <div className=" css_1u763tv">
                        <input name="email" placeholder="email@example.com"
                          className="css_10xzrfw" />
                        <svg width="18" height="18" viewBox="0 0 18 18"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.3281 2.8125H2.67188C1.50677 2.8125 0.5625 3.73579 0.5625 4.875V13.125C0.5625 14.2642 1.50677 15.1875 2.67188 15.1875H15.3281C16.4932 15.1875 17.4375 14.2642 17.4375 13.125V4.875C17.4375 3.73579 16.4916 2.8125 15.3281 2.8125ZM2.67188 4.35938H15.3281C15.6189 4.35938 15.8555 4.5907 15.8555 4.875V5.58914L10.3579 10.0396C9.59359 10.661 8.40212 10.6606 7.63978 10.0401L2.14453 5.59043V4.875C2.14453 4.59141 2.38118 4.35938 2.67188 4.35938ZM15.3281 13.6406H2.67188C2.38111 13.6406 2.14453 13.4093 2.14453 13.125V7.60459L6.63025 11.2591C7.29602 11.7973 8.13647 12.0938 9 12.0938C9.86353 12.0938 10.7056 11.7977 11.3721 11.2565L15.8555 7.60459V13.125C15.8555 13.4086 15.6182 13.6406 15.3281 13.6406Z"
                            fill="#535961"></path>
                        </svg></div>
                    </label>
                      <div className="css_fiamws">
                        <div className="rallit-icon css_12r39mb"><svg width="12" height="12" fill="none" viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.686 2.686a.635.635 0 0 1 .897 0L8 7.103l4.417-4.417a.635.635 0 0 1 .897.897L8.897 8l4.417 4.417a.635.635 0 1 1-.897.897L8 8.897l-4.417 4.417a.635.635 0 1 1-.897-.897L7.103 8 2.686 3.583a.635.635 0 0 1 0-.897z"
                            fill="#e50000"></path>
                        </svg></div><span className="css_ce81wa">-</span>
                      </div>
                    </div>
                    <div data-error="false" className="css_11yoqnl"><label><span className="css_817y00"><span>주소</span><span
                      className="css_mdh6b">*</span></span>
                      <div className=" css_1u763tv">
                        <input type="text" placeholder="경기도 성남시 분당구" maxlength="80" className="css_10xzrfw" />
                        <button type="button"
                          aria-label="search" className="css_1gerhyj"><svg width="16" height="16" fill="none"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.374 8.75a5.623 5.623 0 0 1-5.625 5.625A5.627 5.627 0 0 1 3.124 8.75a5.625 5.625 0 1 1 11.25 0zm-1.025 5.925a7.5 7.5 0 1 1 1.325-1.325l3.8 3.8a.938.938 0 1 1-1.325 1.325l-3.8-3.8z"
                              fill="#535961"></path>
                          </svg></button></div>
                    </label>
                      <div className="css_fiamws">
                        <div className="rallit-icon css_12r39mb"><svg width="12" height="12" fill="none" viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.686 2.686a.635.635 0 0 1 .897 0L8 7.103l4.417-4.417a.635.635 0 0 1 .897.897L8.897 8l4.417 4.417a.635.635 0 1 1-.897.897L8 8.897l-4.417 4.417a.635.635 0 1 1-.897-.897L7.103 8 2.686 3.583a.635.635 0 0 1 0-.897z"
                            fill="#e50000"></path>
                        </svg></div><span className="css_ce81wa">-</span>
                      </div>
                    </div>
                    <div className="css_ry0pp1"><label><span className="css_817y00"><span>선생님 소개</span></span>
                      <div className="css_1vvgunx" aria-hidden="true"><textarea name="coverLetter"
                        placeholder="학생에게 특별한 인상을 줄 수 있는 선생님만의 소개 글을 작성해 보세요!" spellcheck="false" data-testid=""
                        maxlength="600" className="css_1f6rmap"></textarea></div>
                    </label>
                      <ul className="css_1d7hmzo">
                        <li role="default">
                          <div className="css_ab5dye">
                            <div className="css_4duij1"><svg width="14" height="14" fill="none" viewBox="0 0 14 14"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.057 3.692a.657.657 0 0 1 0 .928l-6.343 6.344a.657.657 0 0 1-.928 0L1.942 8.12a.656.656 0 0 1 .928-.927l2.38 2.38 5.88-5.88a.656.656 0 0 1 .927 0z"
                                fill="#a1aab2"></path>
                            </svg></div><span className="css_nwu3e5">600자 이내</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="css_1nd0w80">
                  <div className="css_xi606m"><label className="css_1v0y0ql">
                    <div className="css_vx1dyz">
                      <div className="css_wbgzev">
                        <input type="file" accept=".jpg,.jpeg,.png" className="css_14ly3ew" />
                        <img alt=""
                          className="css_14dxa7q" />
                        <div className="image-uploader-cover css_1ngnjh4"></div>
                        <div className="rallit-icon css_12r39mb"><svg width="32" height="32" fill="none" viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M.889 4.966a1.86 1.86 0 0 1 1.864-1.855H23.53c1.03 0 1.864.83 1.864 1.855v7.83a.794.794 0 0 1-.799.788.794.794 0 0 1-.799-.788v-7.83a.266.266 0 0 0-.266-.265H2.753a.266.266 0 0 0-.266.265v16.43c0 .146.12.264.266.264h3.125a.82.82 0 0 1 .14-.195l9.706-10.106a1.947 1.947 0 0 1 2.766-.035l3.394 3.357c.155.153.242.36.242.577 0 .73-.894 1.094-1.416.578l-3.39-3.354a.278.278 0 0 0-.396.005l-8.81 9.173h6.671a.8.8 0 0 1 .807.795.8.8 0 0 1-.806.795H2.753A1.86 1.86 0 0 1 .89 21.395V4.966z"
                            fill="#c6cad0"></path>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.08 13.71a3.454 3.454 0 0 0 3.463-3.444A3.454 3.454 0 0 0 8.08 6.82a3.454 3.454 0 0 0-3.462 3.445A3.454 3.454 0 0 0 8.08 13.71zm0-1.59a1.86 1.86 0 0 0 1.865-1.854A1.86 1.86 0 0 0 8.08 8.41a1.86 1.86 0 0 0-1.864 1.855A1.86 1.86 0 0 0 8.08 12.12z"
                            fill="#c6cad0"></path>
                          <path
                            d="M25.18 16.246a.859.859 0 0 0-1.206 0 .835.835 0 0 0-.25.595v4.763h-4.83a.858.858 0 0 0-.602.246.834.834 0 0 0 0 1.189c.16.158.376.246.602.246h4.83v4.764c0 .222.09.436.25.594a.858.858 0 0 0 1.205 0 .835.835 0 0 0 .25-.595v-4.763h4.83a.858.858 0 0 0 .602-.246.834.834 0 0 0 0-1.189.858.858 0 0 0-.602-.246h-4.83v-4.763a.835.835 0 0 0-.25-.595z"
                            fill="#c6cad0"></path>
                        </svg></div>
                        <p className="css_hdg8y2">업로드할 이미지를 첨부해 주세요.</p><button className="css_1gx0rvp" type="button">이미지
                          업로드</button>
                      </div>
                    </div>
                  </label><span className="css_hspij9">- 가로 600px, 세로 600px / 5MB이하<br />- 등록 가능 확장자: jpg, png , jpeg</span>
                  </div>

                </div>
              </div>
            </header>
            <section className="css_1jmiz42">
              <h2 className="visually-hidden css_fyikhi">추가정보</h2>
              <div className="css_5m4ka1">
                <div>
                  <div className="css_1qz0a5d">
                    <section id="jobs-section" className="css_c3ve7p">
                      <header className="css_nxdy76">
                        <div className="css_swd362">
                          <div className="css_1siu9uq">
                            <h3 className="css_1vin2lb">수업 과목</h3><span aria-label="필수" className="css_gak923">*</span>
                          </div>
                          <div className="css_zdpt2t"></div>
                        </div>
                      </header>
                      <div className="css_1dw3lh5">
                        <div data-testid="dropdownWrapper" className="dropdown css_180u3m1">
                          <div className="css_79elbk">
                            <div className="css_13o7eu2"><span className="css_817y00"><span>과목 선택</span></span>
                              <div data-testid="dropdownTarget" className="dropdown-target css_18ia305">
                                <input type="text"
                                  role="combobox" aria-haspopup="listbox" aria-expanded="false"
                                  aria-labelledby="dropdown_listbox" aria-controls="dropdown_listbox"
                                  name="interestedJobGroup" data-testid="inputDropdown" readonly=""
                                  className="css_14ly3ew" />
                                <span data-testid="displayName" className="css_1i1o4y5">전체</span>
                                <div className="rallit-icon icon-wrapper css_116b9m9"><svg width="18" height="18" fill="none"
                                  viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M14.378 6.997c.33.33.33.864 0 1.193l-4.781 4.782a.844.844 0 0 1-1.194 0L3.623 8.19a.844.844 0 1 1 1.192-1.193L9 11.182l4.185-4.185a.844.844 0 0 1 1.193 0z"
                                    fill="#535961"></path>
                                </svg></div>
                              </div>
                            </div>
                            <ul role="listbox" id="dropdown_listbox" className="css_1x0fcs1">
                              <li aria-selected="true" role="option" tabindex="0"
                                className="option option--selected css_ivzpf6"><span>전체</span></li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>개발</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>게임개발</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>디자인</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>기획</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>마케팅</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>경영/인사</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>영업</span>
                              </li>
                              <li aria-selected="false" role="option" tabindex="0" className="option css_ivzpf6">
                                <span>엔지니어링</span>
                              </li>
                            </ul>
                          </div>
                          <div className="css_fiamws">
                            <div className="rallit-icon css_12r39mb"><svg width="12" height="12" fill="none"
                              viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.686 2.686a.635.635 0 0 1 .897 0L8 7.103l4.417-4.417a.635.635 0 0 1 .897.897L8.897 8l4.417 4.417a.635.635 0 1 1-.897.897L8 8.897l-4.417 4.417a.635.635 0 1 1-.897-.897L7.103 8 2.686 3.583a.635.635 0 0 1 0-.897z"
                                fill="#e50000"></path>
                            </svg></div><span className="css_ce81wa">-</span>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="portfolio-section" className="css_1p5dvd8">
                      <header className="css_nxdy76">
                        <div className="css_swd362">
                          <div className="css_1siu9uq">
                            <h3 className="css_1vin2lb">증빙 서류</h3><span className="css_18hl5m5">최대 5개</span>
                          </div>
                          <div className="css_zdpt2t"></div>
                        </div>
                      </header>
                      <section>
                        <h4 className="css_fstzjo">첨부 파일</h4><button className="css_1o00n93" type="button">
                          <div className="rallit-icon css_12r39mb"><svg width="16" height="16" viewBox="0 0 14 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7 .875c.483 0 .875.392.875.875v10.5a.875.875 0 0 1-1.75 0V1.75c0-.483.392-.875.875-.875z"
                              fill="#535961"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.125 7a.875.875 0 0 1-.875.875H1.75a.875.875 0 1 1 0-1.75h10.5c.483 0 .875.392.875.875z"
                              fill="#535961"></path>
                          </svg></div>파일 추가
                        </button>
                        <input type="file" name="attachFile" accept=".pdf,.jpg,.png" className="css_1hyfx7x" />
                        <ul className="css_wdosil"></ul>
                        <ul className="css_u7t9ih">
                          <li>
                            <p className="css_oz575p">첨부파일 업로드는 최대 5개까지 제출 가능해요.</p>
                          </li>
                          <li>
                            <p className="css_oz575p">첨부 파일은 PDF, JPG, PNG 형식으로 업로드 가능하며, 각 항목당 업로드할 수 있는 첨부 파일의 최대 용량은 10MB예요.
                            </p>
                          </li>
                        </ul>
                      </section>
                    </section>
                    <section id="introduce-section" className="css_c3ve7p">
                      <header className="css_nxdy76">
                        <div className="css_swd362">
                          <div className="css_1siu9uq">
                            <h3 className="css_1vin2lb">자기소개</h3><span className="css_18hl5m5">최대 5,000자</span>
                          </div>
                          <div className="css_zdpt2t"></div>
                        </div>
                      </header>
                      <div className="css_180u3m1"><label>
                        <div className="css_1vvgunx" aria-hidden="true">
                          <textarea name="introduce"
                            placeholder="수업 경험과 핵심 역량 등 구체적인 내용을 작성해 보세요." spellcheck="false" data-testid=""
                            maxlength="5000" className="css_1ctunha"></textarea>
                        </div>
                      </label>
                        <div className="css_fiamws">
                          <div className="rallit-icon css_12r39mb"><svg width="12" height="12" fill="none" viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.686 2.686a.635.635 0 0 1 .897 0L8 7.103l4.417-4.417a.635.635 0 0 1 .897.897L8.897 8l4.417 4.417a.635.635 0 1 1-.897.897L8 8.897l-4.417 4.417a.635.635 0 1 1-.897-.897L7.103 8 2.686 3.583a.635.635 0 0 1 0-.897z"
                              fill="#e50000"></path>
                          </svg></div><span className="css_ce81wa">-</span>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div>
                  <section className="css_2g8nzb">
                    <button className="css_t0671v" type="submit">선생님 등록</button>

                  </section>
                </div>
              </div>
            </section>

            <footer className="css_2rxz80">
              <div className="css_1egouti"><button className="css_1vmctu7" type="submit">선생님 등록</button></div>
            </footer>
          </form>
        </article>
      </Main>
    </>
  )

}
