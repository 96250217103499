import Nav from "../../../components/Nav";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet-async";
import styles from "../Post/Post.module.css";

export default function Post2() {
  return (
    <>
      <Helmet>
        <title>영재 소년, 택배 상하차를 뛰다. - 류용택(Kyle Ryu)</title>

        <meta
          property="og:image"
          content="https://kyleryu.com/images/img2.png"
        />
        <meta property="og:description" content="그들에게 상처받지 마세요." />
        <meta
          property="og:title"
          content="영재 소년, 택배 상하차를 뛰다. - 류용택(Kyle Ryu)"
        />
      </Helmet>
      <Nav />
      <div className={styles.mainDiv}>
        <div
          className={`${styles["sc-jObWnj"]} ${styles["sc-dPiLbb"]} ${styles.cMpExe}`}
        >
          <div className={styles["sc-efQSVx"]} />
          <div
            className={`${styles["sc-dvQaRk"]} ${styles.ckXmXH} ${styles["sc-iFMAIt"]} ${styles.hvSIqH}`}
          >
            <div className={styles["head-wrapper"]}>
              <h1>영재 소년, 택배 상하차를 뛰다.</h1>
              <div className={styles["sc-iqVWFU"]}>
                <div className={styles.information}>
                  <span>2023년 11월 21일</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles["sc-eldieg"]} ${styles.esFXbZ}`}>
            <div className={`${styles["sc-bQtKYq"]} ${styles.fZOLYn}`}>
              <div
                className={`${styles["sc-fXEqDS"]} ${styles.kLqDvr} ${styles["atom-one"]}`}
              >
                {/* 글 시작 */}
                <blockquote>
                  <p className={styles.strong}>
                    "못 배워서 이런 일이나 하지."라는 말도 들었습니다.
                    <br />
                    그들에게 상처받지 마세요.
                  </p>
                </blockquote>
                <br />
                <p>
                  저는 완벽한 사람이 아닙니다. 패기 넘치는 젊음도 아닙니다.
                  오히려 계속 깨지면서 실패가 당연해졌습니다.
                </p>
                <br />
                <p>
                  <strong>세상은 결과로 말하는 곳</strong>임을 잘 알고 있습니다.
                  이 글을 기반으로 더 빨리 실패하고, 더 빠르게 성장하겠습니다.
                  훗날 결과로 말할 수 있도록, 도망치지 않도록 기록을 남깁니다.
                </p>
                <br />

                {/* [ 1 ] 세 번의 실패 */}
                <h2>[ 1 ] 세 번의 실패</h2>
                <p>
                  길게도 써보고, 사진도 많이 넣어봤습니다. 아무도 안 읽을 것
                  같아서 다 지우고 핵심만 담았습니다.
                </p>
                <br />

                <h3>1) 음성 기반 커리어 플랫폼, 프로리슨</h3>
                <p>
                  모든 자원이 부족한 스타트업은 뾰족해야 합니다. 무엇을 필요로
                  하는지 시장조사를 할 게 아닙니다.{" "}
                  <strong>무엇이 가장 불편한지</strong> 찾아야 합니다. 반대로
                  실행해서 망했습니다. 해당 분야 프로의 <strong>불편함</strong>
                  을 <strong>경청</strong>하겠다는 의미가 지금의
                  '프로리슨'입니다.
                </p>
                <br />

                <h3>2) 웹소설 작가 서포팅 플랫폼, 노벨서포터</h3>
                <p>
                  이번에는 무엇이 가장 불편한지 찾았습니다. 해결책도 꾸준히
                  테스트하며 검증했습니다. 하지만 이곳에서 제가 생각한 혁신을
                  이루려면 많은 자금이 필요했습니다. 겨울이 왔습니다.{" "}
                  <strong>투자 기반이 아닌 자생력 있는 아이템</strong>이
                  필요했습니다.
                </p>
                <br />

                <blockquote>
                  <p>
                    글을 줄였지만 모든 아이템에 전심으로, 밤낮없이 매달렸습니다.
                    커뮤니티와 자료 분석 후 작가님들, 업계 관계자분들을 만났고
                    관련 서적도 읽었습니다. 개발보다는 조사와 분석, 발로 뛴
                    시간이 더 많았던 것 같습니다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/5e873226-3cf5-4c1e-bcb9-52074c4f9a3c/image.jpeg" />
                </blockquote>
                <br />

                <h3>3) 과외 중개 플랫폼, 프로과외</h3>
                <p>
                  25~80%에 달하는 수수료를 10%로 낮추고 업계 시스템을 개선하는
                  게 1차 목표였습니다. 누적된 불만과 요구사항도 명확했고요.
                  플랫폼 특성상 확실한 Lock-In 효과가 없으면 가격경쟁이죠.
                  혼자라서 버틸 자신이 있었습니다. 구현하고 유지 보수할 핵심
                  기능도 많지 않았습니다. 출시를 앞두고{" "}
                  <strong>과외 앱 살인사건</strong>이 발생했습니다.
                </p>
                <br />

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://sgunews.sogang.ac.kr/front/cmsboardview.do?siteId=sgunews&bbsConfigFK=3614&pkid=863252"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      서강학보
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/95e4c2e0-637b-4e31-a503-67790a9398ac/image.jpeg" />
                </blockquote>
                <br />

                <p>이제 '핵심 교훈'과 '앞으로의 계획'을 풀어보겠습니다.</p>
                <br />

                <p>
                  <strong>핵심 교훈</strong>
                </p>
                <p>[ 2 ] 사업계획서 X, 빠른 테스트 O</p>
                <p>[ 3 ] 기술의 함정</p>
                <br />

                <p>
                  <strong>앞으로의 계획</strong>
                </p>
                <p>[ 4 ] 왜 다시 어려운 길을 갑니까?</p>
                <br />

                {/* [ 2 ] 사업계획서 X, 빠른 테스트 O */}
                <h2>[ 2 ] 사업계획서 X, 빠른 테스트 O</h2>
                <p>
                  스타트업은 최고의 경력자가 모여도 실패합니다. 성공적 엑싯을
                  경험한 연쇄 창업가의 다음 제품이 별다른 호응을 얻지 못하고
                  사라지는 사례도 보셨을 겁니다. 그래서{" "}
                  <strong>더 작고, 더 빠르게</strong> 시도해야 합니다.
                </p>
                <br />
                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.albertosavoia.com/"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      구글 최고의 혁신 전문가, 알베르토 사보이아
                    </a>{" "}
                  </p>
                  <p>
                    "대부분의 신제품은 시장에서 실패한다.{" "}
                    <strong>유능하게 실행해도 마찬가지</strong>다."
                  </p>
                </blockquote>
                <br />
                <p>
                  맥킨지 출신 대표의 사업계획서도 실패합니다. 완벽한 조사와
                  전략, 수많은 분석 기법들? 다음 라운드에 필요한 것 같습니다.
                  일단 <strong>'될 놈'</strong>을 찾아야죠. 그러려면 정말
                  가벼워야 합니다.
                </p>
                <br />
                <p>
                  멋들어진 사업계획서 쓸 시간에{" "}
                  <strong>아주 가벼운 테스트 제품</strong>을 만들어야 합니다.
                  투자를 위한 MVP를 말하는 게 아닙니다. 그것보다 훨씬 가벼워야
                  합니다. 각종 지원 사업과 완성도에 대한 집착이 독이 됐습니다.
                </p>
                <br />
                <blockquote>
                  <p>
                    초기 대표님들 연락이 많이 왔습니다. 이 글보다 짧게, 실패한
                    키워드와 앞으로 풀 주제를 꺼냈습니다. 갑자기 조언을
                    주십니다.{" "}
                    <strong>
                      "어려운 주제만 하시는 거 같은데... 사업계획서 써보셨어요?"
                    </strong>
                    ,{" "}
                    <strong>
                      "어린데 취직이나 해요~ 직장 경험이 없으니까 세 번이나
                      실패하는 거야."
                    </strong>
                  </p>
                  <br />

                  <p>
                    3~5년 차 경력, 이제 사업을 시작하신 분들입니다. "네, 말씀 다
                    맞습니다."라고 넘기는 편입니다. 제가 어떤 자세와 밀도로
                    살아왔는지 모르시니까요. 굳이 꺼낼 필요도 없고요. 오히려
                    대선배님께서 존중과 격려, 깊은 통찰력을 보여주십니다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/21486cd4-74d7-4905-9376-9156fec4aa86/image.jpeg" />
                </blockquote>
                <br />

                {/* [ 3 ] 기술의 함정 */}
                <h2>[ 3 ] 기술의 함정</h2>
                <p>
                  세 번째 아이템 '과외 중개 플랫폼', 업계 1위의 웹 기술
                  스택입니다. 세상에는 jQuery와 PHP 만으로 성공한 회사도 정말
                  많습니다. 개발을 하다 보면 기술에 매몰되기 쉽죠. 그러나 0에서
                  1을 만들 때 생각보다 대단한 기술이 필요하지 않습니다. 항상{" "}
                  <strong>이 기술과 코드가 만드는 사업적 가치</strong>에
                  집중해야 합니다.
                </p>
                <br />

                <p>
                  가난한 초기 스타트업인데 완벽한 설계? 기술 부도보다 자금
                  고갈이 빠릅니다. 토스, 배달의민족 같은 100을 따라 하다간 굶어
                  죽습니다.{" "}
                  <strong>일단 0에서 1을 만들어야 살아남습니다.</strong> 그들도
                  처음부터 100은 아니었습니다.
                </p>
                <br />

                <p>
                  물론 적절한 부채 상환은 중요합니다. 사업적 관점에 치우친
                  경영자는 기술 부채의 위험성을 간과합니다. 결국 유능한 개발자는
                  떠나고 고인물만 남습니다. 기업 리뷰를 보니 리액트 리뉴얼을
                  시도하는데 <strong>과도하게 쌓인 기술 부채</strong>가 발목을
                  잡고 있었어요. 기술, 사업 어느 한 쪽으로 쏠리면 안 됩니다. 둘
                  다 챙겨야 합니다.
                </p>
                <br />

                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/c84983c9-6342-4033-b211-775e9fde7ed2/image.png" />
                </blockquote>
                <br />

                {/* [ 4 ] 왜 다시 어려운 길을 갑니까? */}
                <h2>[ 4 ] 왜 다시 어려운 길을 갑니까?</h2>
                <p>
                  과외 앱 살인사건 이후{" "}
                  <strong>'치안 공백', '안전'이라는 키워드</strong>로
                  이동했습니다. 범위가 너무 넓죠? 핵심 고객, 누가 가장 불편한지
                  찾아야 합니다.
                </p>
                <br />

                <p>
                  <strong>'여성'</strong>이었습니다. 그중에서도{" "}
                  <strong>'1인 가구'</strong>입니다.
                </p>
                <br />

                <p>
                  호신용 스프레이? CCTV? 다 좋습니다. 그런데 왜 계속{" "}
                  <strong>막연한 두려움</strong>이 남을까요?{" "}
                  <strong>혼자라는 불안감</strong>이 그대로입니다.
                </p>
                <br />
                <blockquote>
                  <p>여성 1인 가구 수는 꾸준히 증가하고 있습니다.</p>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.joongang.co.kr/article/25004463#home"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      The JoongAng
                    </a>
                    <img src="https://velog.velcdn.com/images/kyleryu/post/e4d96a73-90f3-4021-9df5-62e792fe886f/image.jpeg" />
                  </p>
                  <br />
                </blockquote>
                <br />
                <p>
                  이 <strong>'혼자라는 불안감'을 해결</strong>할 계획입니다.
                  저는 완벽한 사람이 아니고, 혼자 가면 느립니다. 제가 세우는
                  가설이 틀릴 수도 있습니다. 그래서{" "}
                  <strong>함께 가려고요.</strong>
                </p>
                <br />

                <p>
                  지금까지 왜 혼자 했냐고요? 어딜 가든 리더였고, 사람
                  좋아합니다. 그런데 사람을 이끈다는 것에 얼마나 큰 책임감이
                  필요한지도 잘 압니다. 확실한 궤도에 오르거나, 정말 함께 풀고
                  싶은 문제가 아니면 혼자 간다는 생각이었습니다.
                </p>
                <br />

                <p>
                  누군가 그러더군요.{" "}
                  <strong>
                    "실패를 반복하다 보면 10년을 걸 수 있는 진짜 문제를 만나게
                    된다."
                  </strong>
                  라고요. 엄청난 문제를 찾았을 때 창업하는 게 아니라, 뭐라도
                  해보면서 실패하는 게 낫다고 판단했습니다. 그 결과가 세 번의
                  실패, 다음 문제, 지금 이 글입니다.
                </p>
                <br />

                <p>
                  이 문제가 10년을 걸만한 일이냐고요? 모릅니다. 온갖 시련에
                  부딪히고 깨져봐야 압니다. 그래도 함께 풀고 싶은 주제는
                  맞습니다.
                </p>
                <br />

                <p>
                  실패해서 사라질 수도 있습니다. 많이 아프겠죠. 하지만 최선을
                  다했음에도 실패한다면, 그 실패는 다음에 올 위대한 이들의
                  자양분이 될 겁니다. 그러면{" "}
                  <strong>더 나은 세상에 기여한 거 아닙니까?</strong>
                </p>
                <br />

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://youtu.be/B9LIYb3BIQ8?si=MetLxJBdAnXe8b6c"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      https://youtu.be/B9LIYb3BIQ8?si=MetLxJBdAnXe8b6c
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/e33160c0-5aad-4312-826d-2500de9cf3fd/image.png" />
                </blockquote>
                <br />

                <p>
                  업계 선배님께서 제 짧은 인생 굴곡을 들으신 뒤 이런 질문을
                  주셨습니다.
                </p>
                <br />

                <p>
                  "대표님, 기껏 과외 같은 쉬운 길 찾고서{" "}
                  <strong>왜 다시 어려운 길을 갑니까?</strong>"
                </p>
                <br />

                <p>
                  제가 뭐라고 말씀드렸을까요? 자세히는 말씀 못 드리지만 주제는
                  '머리가 아니라 가슴이 시켜서'입니다. 제가 배가 불러서, 덜
                  깨져서 그럴까요? 아뇨, 정말 서럽게 울어봤습니다. 그래서{" "}
                  <strong>
                    한 번 사는 인생, 가슴이 시키는 일을 하고 싶었습니다.
                  </strong>
                </p>
                <br />

                <p>
                  실패할 수도 있습니다. 아니, 실패할 겁니다. 운이 좋으면 성공할
                  겁니다. 그래도 끝까지 함께 풀어보고 싶다면,{" "}
                  <strong>'안전'</strong>이라는 영역을 혁신하고 싶다면 언제든
                  연락 주세요.
                </p>
                <br />

                <p>
                  <strong>kyle@prolisten.net</strong>
                </p>
                <br />
                <br />

                {/* 맺음말 */}
                <h2>맺음말</h2>
                <p>
                  이전 글 때문에 저를 금수저라 생각하셨나요? 학벌도 대단하겠지?
                  부모님은 대기업 사내 부부셨고, 이후 벤처기업을 운영하시는
                  아버지를 보며 CEO라는 꿈을 키웠습니다. 큰 부족함 없이 자랐고,
                  유학을 꿈꿨던 시절도 있습니다. 그런데{" "}
                  <strong>삶이란 게 항상 뜻대로 풀리지는 않더라고요.</strong>
                </p>
                <br />

                <p>
                  택배 상하차를 뛰었고, 식당 홀서빙을 했습니다.{" "}
                  <strong>"못 배워서 이런 일이나 하지."</strong>라는 말도
                  들었습니다. 태풍이 오는 날 우유배달을 하면서 울었던 기억도
                  납니다. 그렇게 퇴근한 뒤 책을 읽고, 강의를 들으며
                  공부했습니다.
                </p>
                <br />

                <p>
                  목표에 도달할 수 있다면 자존심 따위는 쉽게 내려놓습니다.
                  하지만 제 삶의 무게와 가치는 결코 가볍지 않습니다.{" "}
                  <strong>
                    나의 겉모습이나 한 면만 보고 모든 걸 아는 듯 훈수 두는
                    사람을 멀리하세요.
                  </strong>{" "}
                  많이 모자라고, 깊이도 얕은 사람입니다.
                </p>
                <br />

                <p>
                  본인이 제일 잘났다며 남을 가르치려 드는 오만한 자는 '성장할 수
                  없는 사람'입니다. <strong>그들에게 상처받지 마세요.</strong>{" "}
                  무례함과 솔직함은 다른 말입니다. 상호 존중이 결여된 조직과
                  사람은 반드시 무너집니다.
                </p>
                <br />

                <p>
                  부족한 글 읽어주셔서 감사합니다. 다양한 고민 속에서 오늘도
                  치열하게 살아가는 모든 분들을 응원합니다. 이 글이 조금이라도
                  도움이 됐기를, 작게나마 힘과 위로가 되었기를 바랍니다.
                </p>
                <br />

                {/* <p>
                  추가 발행:
                  <br />
                  <a
                    href="https://velog.io/@kyleryu/10%EC%82%B4-%EA%BC%AC%EB%A7%88-%EA%B0%9C%EB%B0%9C%EC%9E%90-%ED%8C%90%EA%B5%90-%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EB%8C%80%ED%91%9C%EA%B0%80-%EB%90%98%EB%8B%A4"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Velog
                  </a>
                  <br />
                  <a
                    href="https://eopla.net/magazines/8287"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    EO Planet
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
