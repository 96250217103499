import Nav from '../../components/Nav';
import styled from 'styled-components';
import styles from './Profile.module.css';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';

let Anchor = styled.a`
display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  height: 100%;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 500;
  color: rgb(83, 89, 97);
  cursor: pointer;
  border-radius: 6px;

  color: ${(props) => props.active ? 'rgb(234, 88, 12)' : 'inherit'};
  &:hover{  
    color: rgb(234, 88, 12);
  }
`

let Para = styled.p`
  margin: 0px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  text-align: start;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  grid-area: title / title / title / title;

  color: ${(props) => props.active ? 'rgb(234, 88, 12)' : 'inherit'};
  &:hover{  
    color: rgb(234, 88, 12);
  }
`


export default function Profile() {

  // 모달
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  }

  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useLogout();
  const { user } = useAuthContext();

  return (
    <>
      <Nav />
      <main className={styles.main}>
        <div className={styles.main_inner}>

          <nav className={styles.css_uebuml}>
            <div className={styles.css_1qtribh}>
              <div className={styles.css_139kjpe} onClick={toggleModal}>
                <span>프로필</span>
                <button className={styles.css_1vgm1p2} type="button">
                  <div className={styles.css_12r39mb}>
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M.938 2.25c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.125h-9a.563.563 0 0 1-.563-.562zM.938 6c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.125h-9A.563.563 0 0 1 .937 6zM.938 9.75c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.126h-9a.562.562 0 0 1-.563-.563z"
                        fill="#535961"></path>
                    </svg>
                  </div>
                </button>
              </div>
              <div className={styles.css_11yz4m4}>
                {/* 모달_1 */}
                {modal && (
                  <div className={styles.css_gqe409}>
                    <ul className={styles.css_733rfy}>
                      <li role="menuitem" className={styles.css_83cc5o} onClick={() => { navigate('/profile') }}>
                        <div className={styles.css_47e4vb}>
                          <Para active={location.pathname === '/profile'}>프로필</Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                      <li role="menuitem" className={styles.css_83cc5o} onClick={() => { navigate('/account') }}>
                        <div className={styles.css_47e4vb}>
                          <Para active={location.pathname === '/account'}>계정 설정</Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                      <div className={styles.css_yrphod}></div>
                      <li role="menuitem" className={styles.css_83cc5o} onClick={logout}>
                        <div className={styles.css_47e4vb}>
                          <Para>로그아웃</Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {/* 모달_2 */}
            {modal && (
              <div role="none" className={styles.css_ntzr4d} onClick={toggleModal}></div>
            )}
          </nav>

          <aside className={styles.css_1xgvj9r}>
            <ul className={styles.css_1b7sljm}>
              <li className={styles.css_e0v95d}>
                <Anchor active={location.pathname === '/profile'} onClick={() => { navigate('/profile') }}>프로필</Anchor>
              </li>
              <li className={styles.css_e0v95d}>
                <Anchor active={location.pathname === '/account'} onClick={() => { navigate('/account') }}>계정 설정</Anchor>
              </li>
              <li className={styles.css_e0v95d} onClick={logout}>
                <Anchor>로그아웃</Anchor>
              </li>
            </ul>
            <div className={styles.css_4cw3x0}></div>
          </aside>

          <section className={styles.css_8jj0t0}>
            <header>
              <h1 className={styles.css_1w7riy2}>{user.displayName} 님의 프로필</h1>
            </header>
            {/* 회원탈퇴 부분 */}
            {/* <div className={styles.css_1rqfylh}>
              <span>더 이상 프로과외를 이용하지 않는다면</span>
              <a href="/my/settings/delete-account" className={styles.css_1k9gbrn}>
                <span>회원탈퇴</span>
                <div className={styles.css_15uwmeb}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"
                    viewBox="0 0 24 24">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#0084F4"
                      d="M9.33 4.83c.439-.44 1.151-.44 1.59 0l6.375 6.374c.44.44.44 1.152 0 1.591L10.92 19.17a1.125 1.125 0 1 1-1.59-1.59L14.909 12l-5.58-5.58a1.125 1.125 0 0 1 0-1.59z">
                    </path>
                  </svg>
                </div>
              </a>
            </div> */}
          </section>

        </div>
      </main>
    </>
  )

}
