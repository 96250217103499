import Nav from "../../../components/Nav";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet-async";
import styles from "./Post.module.css";

export default function Post() {
  return (
    <>
      <Helmet>
        <title>
          10살 꼬마 개발자, 판교 스타트업 대표가 되다. - 류용택(Kyle Ryu)
        </title>

        <meta
          property="og:image"
          content="https://kyleryu.com/images/img1.png"
        />
        <meta
          property="og:description"
          content="나는 7살에 프로그래밍을 접했다. 그리고 2022년, 25살에 스타트업 대표가 되었다."
        />
        <meta
          property="og:title"
          content="10살 꼬마 개발자, 판교 스타트업 대표가 되다. - 류용택(Kyle Ryu)"
        />
      </Helmet>
      <Nav />
      <div className={styles.mainDiv}>
        <div
          className={`${styles["sc-jObWnj"]} ${styles["sc-dPiLbb"]} ${styles.cMpExe}`}
        >
          <div className={styles["sc-efQSVx"]} />
          <div
            className={`${styles["sc-dvQaRk"]} ${styles.ckXmXH} ${styles["sc-iFMAIt"]} ${styles.hvSIqH}`}
          >
            <div className={styles["head-wrapper"]}>
              <h1>10살 꼬마 개발자, 판교 스타트업 대표가 되다.</h1>
              <div className={styles["sc-iqVWFU"]}>
                <div className={styles.information}>
                  <span>2023년 11월 08일</span>
                </div>
              </div>
            </div>
            {/* <img
              src="https://velog.velcdn.com/images/kyleryu/post/651f7307-f687-4f6e-9090-4e679fa8e04e/image.png"
              alt="post-thumbnail"
              className={`${styles["sc-kTLmzF"]} ${styles.kuRkHh}`}
            /> */}
          </div>
          <div className={`${styles["sc-eldieg"]} ${styles.esFXbZ}`}>
            <div className={`${styles["sc-bQtKYq"]} ${styles.fZOLYn}`}>
              <div
                className={`${styles["sc-fXEqDS"]} ${styles.kLqDvr} ${styles["atom-one"]}`}
              >
                {/* 글 시작 */}
                <p>
                  7살에 프로그래밍을 접했다.
                  <br />
                  그리고 2022년, 25살에 스타트업 대표가 되었다.
                  <br />
                  (가독성을 위해 경어체를 생략했습니다. 양해 부탁드립니다.)
                </p>
                <br />
                <blockquote>
                  <p>
                    카카오와 NC소프트 사옥이 보이는 좋은 환경이다. 9층은 오픈
                    스페이스였는데 이제 지정석으로 변경됐다.
                    <br />
                    <img src="https://velog.velcdn.com/images/kyleryu/post/651f7307-f687-4f6e-9090-4e679fa8e04e/image.png" />
                  </p>
                </blockquote>
                <br />
                <p>
                  창업 후 지금까지 수차례 실패했다.
                  <br />
                  혼자서 일정 궤도까지 올린 후 팀원을 모으고자 했으나 한계에
                  직면했다.
                </p>
                <br />

                <p>
                  어떻게 달려왔는지 돌아보고, 기록으로 남겨서 공유해야겠다고
                  판단했다.
                  <br />
                  얼마나 부족한 사람인지, 어떤 장점을 지닌 사람인지 있는 그대로
                  적었다.
                  <br />
                </p>
                <br />

                <p className={styles.strong}>
                  동료와 멘토를 찾고자 한다.
                  <br /> 가벼운 대화도 괜찮으니{" "}
                  <a
                    href="https://kyleryu.com"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    언제든 연락 주시길.
                  </a>{" "}
                </p>
                <br />
                {/* 01_7살, 프로그래밍을 접하다 */}
                <h2>[ 1 ] 7살, 프로그래밍을 접하다</h2>

                <p>
                  부모님의 영향을 받아 기술과 일찍 친해졌다.{" "}
                  <a
                    href="https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=371271"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    리눅스 네트워크 레퍼런스 바이블
                  </a>
                  이라는 책을 통해서 리눅스라는 단어와 프로그래밍이라는 개념을
                  처음 접했다.
                </p>
                <br />
                <p>
                  어휘력 대비 책이 너무 어려웠다. 서버와 클라이언트가 무엇인지
                  정도만 이해했다. ‘OSI 참조 모델과 TCP/IP 계층’같은 파트는
                  읽다가 잠들었다. 끝없이 시도했지만 안 읽히는 부분은 어쩔 수
                  없었다.
                </p>
                <br />
                <blockquote>
                  <p>
                    창업 전 UI/UX 디자인을 배우러 갔던 학원에서 모니터 받침대로
                    사용되었다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/2f7b759a-fbad-4a3e-abe0-d6251b5fc1a0/image.png" />
                </blockquote>
                <br />
                {/* 02_초등학생, 첫 게임 개발 */}
                <h2>[ 2 ] 초등학생, 첫 게임 개발</h2>
                <p>
                  과학과 컴퓨터에 관심이 많았다. 책장에는 기술 관련 서적이
                  빼곡했고 수시로 꺼내 읽었다. 시간이 흘러 10살 무렵 상세히 이해
                  가능한 책들이 생겼다. 포토샵, 일러스트 같은 디자인 서적과
                  플래시, 웹개발 같은 프로그래밍 서적이었다.
                </p>
                <br />
                <blockquote>
                  <p>
                    3학년 때부터 초등학교 졸업까지 매일같이 게임과 애니메이션을
                    만들었다. 그래서 특기 항목에 프로그래밍과 그래픽이 보인다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/558b5364-80c9-40ce-9724-eab57db0ed88/image.png" />
                </blockquote>
                <br />
                <p>
                  특히 프로그래밍에 미쳐있었다. 생각한 것을 코드로 구현할 때마다
                  정말 행복했다. 그 시절 잠도 아껴가면서 코딩을 했다. 7할은
                  플래시 액션스크립트였다. 맞다, 게임 개발이 유독 재밌었다.
                  플래시 커뮤니티, 개발 커뮤니티에서 많은 도움을 받았다.
                  결과물을 학교 발표에 활용하곤 했다.
                </p>
                <br />
                <blockquote>
                  <p>
                    학교생활에도 성실히 임했다. 사교육을 받지 않았기 때문에
                    시간의 여유가 있었다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/40cef0f4-42b0-4193-bf1c-fcab481a38d4/image.png" />
                </blockquote>
                <br />
                <p>
                  중학교에 진학할 무렵 모든 게임을 끊었다. 좋은 게임을 발견하면
                  ‘어떻게 구현했을까’ 생각했다. 얼른 성인이 돼서 내가 만든
                  서비스로 세상을 바꾸고 싶었다.
                </p>
                <br />
                {/* 03_군 전역, 창업 준비 */}
                <h2>[ 3 ] 군 전역, 창업 준비</h2>
                <p>군 전역 후 창업을 하기로 마음먹었다. 계획을 세워봤다.</p>
                <br />
                <ul className="ml-3">
                  <li>
                    &bull; 기업 형태: 1인 기업으로 시작한다. 많은 스타트업
                    책들과 아티클을 봤을 때 망하더라도 혼자 망해야 한다.어떤
                    기술도 MVP(최소기능제품)를 만들 정도는 빠르게 습득 가능하다.
                    초기 트래픽이 많이 나온다? 오히려 좋다. 혼자 감당하기
                    버겁다면 매각을 하거나 그때 투자를 받아 팀 빌딩을 하면 된다.
                  </li>
                  <br />
                  <li>
                    &bull; 기술: 앱 기반 사업을 한다. 향후 투자 받기도 용이하다.
                    사실 웹이든 앱이든 깊게 들어가면 똑같이 어렵다. 그런데 펀딩
                    기사나 심사역 인터뷰를 분석해 보니 앱 개발 능력을 더 높이
                    평가한다. 웹빌더의 영향력이 반영된 것 같다.
                  </li>
                  <br />
                  <li>
                    &bull; 위치: 판교 또는 강남에서 정부 지원 사무실을 통해
                    시작한다. 투자 유치와 채용에 유리하다.(스타트업 '놀이'가
                    아닌 ‘사업’이다. 사무실은 부차적인 조건일 뿐 핵심은 오직
                    팀과 아이템이다.)
                  </li>
                </ul>
                <br />
                <p>
                  대략 이런 플랜을 기반으로 준비에 돌입했다. 스타트업은 나의
                  오랜 비전이었다. 그래서 항상 행복했고 드디어 살아 숨 쉬는
                  기분이었다. 그렇지만 동시에 정말 고독하고 고된 시간이었다.
                  창업을 계획하고 있다면 마음 독하게 먹자.
                </p>
                <br />
                <p>
                  1인 개발로 안드로이드와 IOS를 모두 감당해야 했기 때문에 크로스
                  플랫폼을 채택했다. 지금도 그렇지만 당시도 리액트 네이티브와
                  플러터가 양대 산맥이었다. 한국 웹 개발이 React로 굳혀지는
                  모양새고 플러터는 구글에서 언제 버릴지 모르는 초기
                  프로덕트였다. 팀 빌딩 측면에서는 리액트 네이티브를 선택하는 게
                  맞았다.
                </p>
                <br />
                <p>
                  그러나 내가 만들고자 하는 제품의 기능은 플러터로 구현하는 것이
                  더 쉬웠다. RN으로 구현하기에는 네이티브 컨트롤이 많이 필요해서
                  부적합했다. 또한 MVP를 만든 뒤 투자를 받을 계획이었다. 이후
                  기술 부채가 쌓이기 전에 RN이나 네이티브 앱으로 마이그레이션
                  하면 된다는 생각에 플러터를 선택했다.
                </p>
                <br />
                {/* 04_UI/UX 디자인을 배워보자 */}
                <h2>[ 4 ] UI/UX 디자인을 배워보자</h2>
                <p>
                  개발을 하면 할수록 디자인이 아쉬웠다. UI/UX 디자인 학원을
                  찾아보았다. 많은 학원 중 커리큘럼이 구체적이고 실용적으로
                  채워진 곳을 찾아갔다. 좋은 선택이었다. 필드에서 오랜 기간
                  활동하신 유능한 선생님을 만났다.
                </p>
                <br />
                <p>
                  개발은 프레임워크나 라이브러리가 아닌 기본기가 중요하다. 코어
                  지식이 잘 잡혀있으면 어떤 프레임워크든 금방 배울 수 있다.
                  디자인 선생님께서도 툴이 아니라 기본기가 중요하다는 말씀을
                  하셨다. 수업 때 기초를 열심히 다진 덕분에 이후 피그마도 금방
                  배웠다. 현재 디자인 작업은 대부분 피그마로 진행한다.
                </p>
                <br />
                <blockquote>
                  <p>
                    배운 지식을 기반으로 리뉴얼한 페이지 중 하나다. 각자 리뉴얼
                    하고 싶은 서비스를 정해서 포토샵, 일러스트, 어도비xd 등을
                    활용해 만들었다.
                  </p>
                  <br />

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ width: "45%" }}>
                      <img
                        src="https://velog.velcdn.com/images/kyleryu/post/1e17eb53-d3a4-4216-af5a-e7bda328eece/image.jpeg"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ width: "45%" }}>
                      <img
                        src="https://velog.velcdn.com/images/kyleryu/post/2d4defd3-8ae9-4e91-8d8c-6b5861286e7e/image.png"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                </blockquote>
                <br />
                {/* 판교 혁신센터, 시작 */}
                <h2>[ 5 ] 판교 혁신센터, 시작</h2>
                <p>
                  디자인 학원까지 수료하고 드디어 창업을 위한 준비를 마쳤다고
                  판단했다. 많이 부족하지만 나머지는 필드에서 부딪치며 성장하면
                  된다는 생각이었다.
                </p>
                <br />
                <p>
                  이제 남은 건 정부 지원 사무실이다. 인재 채용 측면에서는
                  직주근접이 1순위다. 투자 및 브랜딩을 고려하면 판교라는
                  네임밸류가 제일 좋다. 둘 다 만족하는 사무실이 하나 있었다.
                  ‘창조경제혁신센터’
                </p>
                <br />

                <p>
                  이곳은 커리어 플랫폼 ‘인프런’을 운영하는&nbsp;
                  <a
                    href="https://www.hyungjoo.me/%ec%9d%b8%ed%94%84%eb%9e%a9-%ec%9e%ac%eb%ac%b4%ec%a0%81-log-1/"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    인프랩 대표님 블로그
                  </a>
                  를 통해 알게 됐다. 모집 공고를 기다린 뒤 신청하고 합격했다.
                  오랜 준비와 기다림 끝에 2022년 05월 02일 ‘프로리슨’을
                  설립했다.
                </p>
                <br />

                <p>
                  현재까지 어떤 시행착오를 겪어 왔는지, 지금의 생각과 앞으로의
                  계획은 무엇인지 작성해 볼 예정이다. 이 기록들이 내게 힘이
                  되기를, 글을 읽는 분들에게는 타산지석이 되기를 바란다.
                </p>
                <br />
                {/* 맺음말 */}
                <h2>맺음말</h2>
                <p>
                  일찍이 프로그래밍을 접해서 세상을 바꾼 천재들과 달리 나는
                  지극히 평범하다. 하지만 그들과 비교하지 않고 오랜 꿈을
                  좇아가는 중이다.
                </p>
                <br />
                <p>
                  30대에 코딩을 시작해도 되냐고? “당연하죠.”라고 말하고 싶다.
                  만들고 싶은 게 있다면 50대에 시작하셔도 상관없다. 일찍
                  시작하는 건 중요치 않다. 얼마나 밀도 있게 학습하는지가
                  중요하다. 7살에 영어 배웠다고 무조건 잘하는가? 20대, 30대에
                  높은 밀도로 학습한 사람이 더 잘하는 경우가 부지기수이다.
                </p>
                <br />
                <p>
                  쿠팡을 보라. 다들 망한다고 말했다. “계획된 적자? 밑 빠진 독에
                  물 붓기지!”라며 조롱하는 사람들이 다수였다. 그러나 결국 뉴욕
                  증시에 입성했다. 흑자 기업이 되었다. 남들이 뭐라 말하든
                  구체적인 목표와 계획이 있다면 소신껏 살아가자.
                </p>
                <br />
                <br />
                <p>
                  추가 발행:
                  <br />
                  <a
                    href="https://velog.io/@kyleryu/10%EC%82%B4-%EA%BC%AC%EB%A7%88-%EA%B0%9C%EB%B0%9C%EC%9E%90-%ED%8C%90%EA%B5%90-%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EB%8C%80%ED%91%9C%EA%B0%80-%EB%90%98%EB%8B%A4"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Velog
                  </a>
                  <br />
                  <a
                    href="https://eopla.net/magazines/8287"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    EO Planet
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
