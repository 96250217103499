import React from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet-async";
import styles from "./Home.module.css";
import Banner from "../../components/Banner";
import PostList from "../../components/PostList";
import PostListDev from "../../components/PostListDev";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>류용택(Kyle Ryu)</title>
        <meta property="og:image" content="/og.png" />
        <meta
          property="og:description"
          content="Founder and CEO of ProListen"
        />
        <meta property="og:title" content="류용택(Kyle Ryu)" />
        <meta name="url" property="og:url" content="https://kyleryu.com" />
      </Helmet>
      <Nav></Nav>
      <main className={styles.main}>
        <Banner />
        <PostList />
        <PostListDev />
      </main>
      <Footer></Footer>
    </>
  );
}
