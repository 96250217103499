import Nav from "../../components/Nav";
import styled from "styled-components";
import styles from "./Account.module.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import { appAuth } from "../../firebase/config";
import { deleteUser } from "firebase/auth";

let Anchor = styled.a`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  height: 100%;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 500;
  color: rgb(83, 89, 97);
  cursor: pointer;
  border-radius: 6px;

  color: ${(props) => (props.active ? "rgb(234, 88, 12)" : "inherit")};
  &:hover {
    color: rgb(234, 88, 12);
  }
`;

let Para = styled.p`
  margin: 0px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  text-align: start;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  grid-area: title / title / title / title;

  color: ${(props) => (props.active ? "rgb(234, 88, 12)" : "inherit")};
  &:hover {
    color: rgb(234, 88, 12);
  }
`;

export default function Account() {
  // 모달
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useLogout();

  // 계정 삭제 기능
  const deleteAccount = async (event) => {
    event.preventDefault();

    try {
      await deleteUser(appAuth.currentUser);
      alert(
        "자동 로그아웃 후 계정 삭제가 완료됩니다.\n그동안 이용해 주셔서 감사합니다."
      );
      // 로그아웃 처리도 필요할 수 있습니다.
      logout();
    } catch (error) {
      console.error("계정 삭제에 실패하였습니다.", error);
    }
  };

  return (
    <>
      <Nav />
      <main className={styles.main}>
        <div className={styles.main_inner}>
          <nav className={styles.css_uebuml}>
            <div className={styles.css_1qtribh}>
              <div className={styles.css_139kjpe} onClick={toggleModal}>
                <span>계정 설정</span>
                <button className={styles.css_1vgm1p2} type="button">
                  <div className={styles.css_12r39mb}>
                    <svg
                      width="12"
                      height="12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M.938 2.25c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.125h-9a.563.563 0 0 1-.563-.562zM.938 6c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.125h-9A.563.563 0 0 1 .937 6zM.938 9.75c0-.31.251-.563.562-.563h9a.562.562 0 1 1 0 1.126h-9a.562.562 0 0 1-.563-.563z"
                        fill="#535961"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
              <div className={styles.css_11yz4m4}>
                {/* 모달_1 */}
                {modal && (
                  <div className={styles.css_gqe409}>
                    <ul className={styles.css_733rfy}>
                      <li
                        role="menuitem"
                        className={styles.css_83cc5o}
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        <div className={styles.css_47e4vb}>
                          <Para active={location.pathname === "/profile"}>
                            프로필
                          </Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                      <li
                        role="menuitem"
                        className={styles.css_83cc5o}
                        onClick={() => {
                          navigate("/account");
                        }}
                      >
                        <div className={styles.css_47e4vb}>
                          <Para active={location.pathname === "/account"}>
                            계정 설정
                          </Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                      <div className={styles.css_yrphod}></div>
                      <li
                        role="menuitem"
                        className={styles.css_83cc5o}
                        onClick={logout}
                      >
                        <div className={styles.css_47e4vb}>
                          <Para>로그아웃</Para>
                          <p className={styles.css_vzncwq}></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {/* 모달_2 */}
            {modal && (
              <div
                role="none"
                className={styles.css_ntzr4d}
                onClick={toggleModal}
              ></div>
            )}
          </nav>

          <aside className={styles.css_1xgvj9r}>
            <ul className={styles.css_1b7sljm}>
              <li className={styles.css_e0v95d}>
                <Anchor
                  active={location.pathname === "/profile"}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  프로필
                </Anchor>
              </li>
              <li className={styles.css_e0v95d}>
                <Anchor
                  active={location.pathname === "/account"}
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  계정 설정
                </Anchor>
              </li>
              <li className={styles.css_e0v95d} onClick={logout}>
                <Anchor>로그아웃</Anchor>
              </li>
            </ul>
            <div className={styles.css_4cw3x0}></div>
          </aside>

          <section className={styles.css_8jj0t0}>
            <header>
              <h1 className={styles.css_1w7riy2}>계정 설정</h1>
            </header>
            <div className={styles.css_1rqfylh}>
              <span>클릭 시 모든 정보가 즉시 삭제됩니다.</span>
              <a
                href="1"
                className={styles.css_1k9gbrn}
                onClick={deleteAccount}
              >
                <span>계정 삭제</span>
                <div className={styles.css_15uwmeb}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
