import "./Banner.css";
import React, { useEffect } from "react";
import Swiper from "swiper/bundle";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  useEffect(() => {
    const swiper = new Swiper(".swiper", {
      loop: true,

      pagination: {
        el: ".swiper-pagination",
        type: "fraction", // 페이지 표시 방법
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 재생 속도 - 기본값 300ms
      speed: 1000,

      // 자동 재생
      autoplay: {
        delay: 5000, // 5초마다 재생
        disableOnInteraction: false, // 유저가 스와이프 후에도 자동 재생
      },
    });
  }, []);

  const navigate = useNavigate();

  return (
    <section className="css-14c50mj">
      <div className="css-1yposw6">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
          <div className="swiper-wrapper">
            {/* banner1 */}
            <div className="swiper-slide">
              <a
                onClick={() => {
                  navigate("/business/1");
                }}
              >
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner1_sm.webp"
                    }
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner1_bg.webp"
                    }
                  />
                  <img
                    src={process.env.PUBLIC_URL + "../images/banner1_bg.webp"}
                    alt="메인 배너"
                  />
                </picture>
              </a>
            </div>
            {/* banner2 */}
            <div className="swiper-slide">
              <a
                onClick={() => {
                  navigate("/business/2");
                }}
              >
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner2_sm.webp"
                    }
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner2_bg.webp"
                    }
                  />
                  <img
                    src={process.env.PUBLIC_URL + "../images/banner2_bg.webp"}
                    alt="메인 배너"
                  />
                </picture>
              </a>
            </div>
            {/* banner3 */}
            <div className="swiper-slide">
              <a
                onClick={() => {
                  navigate("/business/3");
                }}
              >
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner3_sm.webp"
                    }
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset={
                      process.env.PUBLIC_URL + "../images/banner3_bg.webp"
                    }
                  />
                  <img
                    src={process.env.PUBLIC_URL + "../images/banner3_bg.webp"}
                    alt="메인 배너"
                  />
                </picture>
                {/* <picture>
                  <source
                    media="(max-width: 768px)"
                    srcset="https://velog.velcdn.com/images/kyleryu/post/1ddafb31-8b2a-48c4-8970-b5646e770809/image.png"
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset="https://velog.velcdn.com/images/kyleryu/post/f1c43e87-17c1-4a41-b85e-ab9881f47a30/image.png"
                  />
                  <img
                    src="https://velog.velcdn.com/images/kyleryu/post/f1c43e87-17c1-4a41-b85e-ab9881f47a30/image.png"
                    alt="메인 배너"
                  />
                </picture> */}
              </a>
            </div>
          </div>
        </div>
        <div className="css-y001ci">
          <div className="css-b4963y">
            <p className="css-oz575p swiper-pagination"></p>
          </div>
          <button
            type="button"
            aria-label="이전 배너"
            className="css-1ccx5y7 swiper-button-prev"
          >
            <div className="css-1gvano">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#fff"
                  d="M14.67 19.17c-.439.44-1.151.44-1.59 0l-6.375-6.375a1.125 1.125 0 0 1 0-1.59l6.374-6.375a1.125 1.125 0 0 1 1.591 1.59L9.091 12l5.58 5.58c.439.439.439 1.151 0 1.59z"
                ></path>
              </svg>
            </div>
          </button>
          <button
            type="button"
            aria-label="다음 배너"
            className="css-1ccx5y7 swiper-button-next"
          >
            <div className="css-1gvano">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#fff"
                  d="M9.33 4.83c.439-.44 1.151-.44 1.59 0l6.375 6.374c.44.44.44 1.152 0 1.591L10.92 19.17a1.125 1.125 0 1 1-1.59-1.59L14.909 12l-5.58-5.58a1.125 1.125 0 0 1 0-1.59z"
                ></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
