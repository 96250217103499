import Nav from "../../../components/Nav";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet-async";
import styles from "../Post/Post.module.css";

export default function Post() {
  return (
    <>
      <Helmet>
        <title>개발자가 100억을 버는 가장 빠른 방법 - 류용택(Kyle Ryu)</title>

        <meta
          property="og:image"
          content="https://velog.velcdn.com/images/kyleryu/post/1f31e242-098c-4571-ab62-913d2d9acbb6/image.png"
        />
        <meta
          property="og:description"
          content="한 번 사는 인생, 100억에 도전하시겠습니까?"
        />
        <meta
          property="og:title"
          content="개발자가 100억을 버는 가장 빠른 방법 - 류용택(Kyle Ryu)"
        />
      </Helmet>
      <Nav />
      <div className={styles.mainDiv}>
        <div
          className={`${styles["sc-jObWnj"]} ${styles["sc-dPiLbb"]} ${styles.cMpExe}`}
        >
          <div className={styles["sc-efQSVx"]} />
          <div
            className={`${styles["sc-dvQaRk"]} ${styles.ckXmXH} ${styles["sc-iFMAIt"]} ${styles.hvSIqH}`}
          >
            <div className={styles["head-wrapper"]}>
              <h1>개발자가 100억을 버는 가장 빠른 방법</h1>
              <div className={styles["sc-iqVWFU"]}>
                <div className={styles.information}>
                  <span>2024년 03월 18일</span>
                </div>
              </div>
            </div>
            {/* <img
              src="https://velog.velcdn.com/images/kyleryu/post/651f7307-f687-4f6e-9090-4e679fa8e04e/image.png"
              alt="post-thumbnail"
              className={`${styles["sc-kTLmzF"]} ${styles.kuRkHh}`}
            /> */}
          </div>
          <div className={`${styles["sc-eldieg"]} ${styles.esFXbZ}`}>
            <div className={`${styles["sc-bQtKYq"]} ${styles.fZOLYn}`}>
              <div
                className={`${styles["sc-fXEqDS"]} ${styles.kLqDvr} ${styles["atom-one"]}`}
              >
                {/* 글 시작 */}
                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.chosun.com/national/incident/2024/01/22/3YNI22ZEHRBFHMVXC2UR3XZIYA/"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      집에 ‘5만원 탑’ 쌓아뒀다…
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/1f31e242-098c-4571-ab62-913d2d9acbb6/image.png" />
                </blockquote>

                <p>
                  외주를 수행하는 프리 개발자들은 불법 사이트 또는 프로그램 제작
                  문의를 받아봤을 것이다. 어둠의 세계는 정말 광활하고, 매우 큰
                  금액이 오간다.{" "}
                  <strong>
                    법의 테두리를 벗어난다면, 그리고 직접 운영한다면 누구보다
                    빨리 100억을 벌 수 있는 이들이 개발자
                  </strong>
                  다. 그러나 그 선을 넘는다면 국가에 소속된 전문가들이 평생
                  당신을 쫓을 것이다.
                </p>
                <br />
                <p>
                  피해자의 피눈물은{" "}
                  <strong>늦더라도 반드시 칼이 되어 돌아오기에</strong> 정당한
                  방법으로 돈을 벌어야 한다. 합법적인 100억은 난도가 매우 높다.
                  집과 차는 물론이고 모든 걸 포기할 각오가 필요하다. 그럼에도 한
                  번 사는 인생 100억에 도전하고 싶은가?{" "}
                  <strong>
                    이름만 들으면 뭔가 그럴듯한 ‘스타트업’이 바로 그 방법
                  </strong>
                  이다.
                </p>
                <br />

                <p>
                  스타트업 ‘성공 비법’ 같은 걸 공유하면 좋겠지만 그런 건 없다.
                  성공했다 평가받는 분들의 책도 많이 읽어봤고, 직접 찾아뵙기도
                  했지만 한결같이 운이 좋았다고 말씀하셨다. 그분들조차 스스로를
                  낮추시는데 내 글은 그저 흥미로운 수준만 되더라도 만족한다.
                  이번 글은 <strong>아버지의 벤처기업 이야기</strong>로
                  시작한다. 비법 같은 건 아니지만 100억 또는 새로운 길에
                  도전하는 우리 모두에게 작게나마 도움이 되었으면 좋겠다.
                </p>
                <br />

                {/* [ 1 ] 전략적 투자의 비극 */}
                <h2>[ 1 ] 전략적 투자의 비극</h2>
                <p>
                  벤처 투자는 재무적 투자와 전략적 투자로 구분된다. 말 그대로
                  자본만 들어가는 투자는 재무적 투자,{" "}
                  <strong>
                    사업적인 협력 관계까지 가져가는 투자가 전략적 투자
                  </strong>
                  다.
                </p>
                <br />

                <p>
                  아버지는 대기업과 전략적 투자 관계를 맺기로 하셨다. 주요
                  임원진까지 나서서 믿음을 심어줬다. 그러나 결론은 배신이었다.{" "}
                  <strong>투자 결정은 철회되었고 기술은 그대로 도용</strong>
                  당했다. 대기업의 막강한 파워는 치명상을 입혔다. 핵심 기술을
                  제대로 카피하지 못한 모방품이라 훗날 많은 문제가 발생하긴
                  했지만 이미 열차가 떠난 뒤였다. 온갖 악재들이 겹치며 회사는
                  빠르게 기울었다. 그들 앞에선 특허도 무용지물이었다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.sedaily.com/NewsView/26CJVO5IO6"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      10곳 중 7곳, 대기업 특허분쟁서 운다
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/d0cfb83f-128c-4050-bdb3-625abc47fea6/image.png" />
                </blockquote>
                <br />

                {/* [ 2 ] 사명감 vs 돈 */}
                <h2>[ 2 ] 사명감 vs 돈</h2>
                <p>
                  아버지는 엔지니어, 발명가로서 국가와 인류에 공헌하고 싶다는
                  일념이 있으셨다. 그 길로 대기업에서 나와 신기술에 도전했고,
                  우직하게 한 길만 파셨다. 선배가 창업한 회사에서 이사로
                  재직하며 단신으로 개발과 영업을 도맡았고, 전국을 돌며{" "}
                  <strong>수백억의 매출을 견인</strong>하셨다.
                </p>
                <br />

                <p>
                  화려한 트랙레코드 덕분에 대표로서 새로운 법인을 설립할 때{" "}
                  <strong>엔젤투자로만 10억이 확보</strong>됐다. 하지만 엄청난
                  노력과 성과에도 불구하고 순식간에 공든 탑이 무너졌다. 성공이란
                  단어 뒤에 왜 ‘신화’라는 수식어가 붙는지 아는가? 미치도록
                  어렵기 때문이다. 특히{" "}
                  <strong>사명감을 가질수록 난이도는 극악</strong>이다.
                </p>
                <br />

                <p>
                  아버지는 나의 영웅이셨다. 출장길에 동행할 때면 정말 행복했다.
                  동경하던 세계를 어깨너머로 배울 수 있었으니 말이다. 더 나은
                  세상을 만들겠다는 그 신념이 너무 고되지만 빛나 보였다. 그러나
                  배신의 비수가 꽂혔을 때, 모든 것이 무너져 내릴 때 결국{" "}
                  <strong>돈이 최고의 권력</strong>임을 온몸으로 배웠다.
                </p>
                <br />

                <br />
                {/* [ 3 ] 100억을 버는 가장 빠른 방법 */}
                <h2>[ 3 ] 100억을 버는 가장 빠른 방법</h2>
                <p>
                  기술적 전문성은 있으나 무일푼인 사람이 100억 이상을 벌 수 있는
                  가장 빠른 방법이 뭘까? 도입부에서 말했듯 스타트업이라
                  생각한다. 엑싯 한 번이면 인생이 바뀐다. 물론 대부분 그전에
                  망하지만 희박한 확률에 베팅하는 것이다. 완전한 엑싯 이전에
                  주요 라운드에서 구주 매각으로 현금을 확보하는 방법도 있다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://dealsite.co.kr/articles/85547"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      https://dealsite.co.kr/articles/85547
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/ef02be99-a954-44da-b419-53ce33c19e95/image.png" />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/058561eb-a862-4bc8-a18c-03d2db68f27d/image.png" />
                </blockquote>
                <br />

                <p>
                  본인의 사례를 직접 공유하는 대표님들이 꽤 계신다. 그중에서
                  가장 생생히 표현한 분을 꼽으라면 역시 인프랩 이형주 대표님이
                  아닐까. 야놀자 이수진 대표님처럼 정말{" "}
                  <strong>아무런 배경 없이 회사를 일군 분</strong>이다. 초기
                  스타트업 대표라면, 창업을 꿈꾸는 사람이라면 한 번쯤 읽어보길
                  권한다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.hyungjoo.me/%EC%9D%B8%ED%94%84%EB%9E%A9-%EC%9E%AC%EB%AC%B4%EC%A0%81-log-1/"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      인프랩 스타트업 투자 재무적 Log
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/fc475c6f-a304-4020-b6c4-1afb7a324dd0/image.png" />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/79719069-6a09-44e8-b7d0-0ff34ee09eb4/image.png" />
                </blockquote>
                <br />

                <p>
                  대한민국에서 금융자산 10억 이상을 보유한 자산가는 45만 6천
                  명이다. 100억~300억 구간의 ‘고자산가’는 3만 2천 명, 300억 이상
                  보유한 ‘초고자산가’는 9천 명이다. 많은 사람들이 꿈꾸는 구간은
                  총자산 100억 이상일 것이다. 부자들이 생각하는 부자의 기준도
                  100억이니 말이다. 부자 보고서를 통해 자수성가의 비중이 얼마나
                  되는지, 그들이 어떤 방식으로 부를 쌓았는지 참고하면 보다
                  뚜렷한 목표 정립에 도움이 될 것 같다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.kbfg.com/kbresearch/report/reportView.do?reportId=2000448"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      2023 한국 부자 보고서
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/868e5083-616f-4b39-8b8c-8beecf7af3c0/image.png" />
                  <img
                    src="https://velog.velcdn.com/images/kyleryu/post/78c66808-2c61-4972-a268-ff2a1698a6fb/image.png"
                    alt="부자 보고서"
                    style={{ width: "100%" }}
                  />
                </blockquote>
                <br />

                {/* [ 4 ] 구체적인 방법 */}
                <h2>[ 4 ] 구체적인 방법</h2>
                <p>
                  세상이 너무 편해서 아이디어가 없다고 말하는 개발자들이 많다.
                  실제로 우리가 생각하는 대부분의 아이디어는 이미 시도된
                  아이템이다. 경쟁자가 없는 블루오션은 다 그만한 이유가 있다.
                  그럼 어떡하라고? 많은 방법론이 있지만 10억 달러에 회사를
                  매각한 사나이의 조언을 따라보자. 2분 길이의 짧은 영상이다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.youtube.com/watch?v=lGUPPRIh3QY"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      인스타그램 창업자의 생각하는 방식
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/f86af800-3e40-43a8-bdfa-9cf960226d41/image.png" />
                </blockquote>
                <br />

                <p>
                  혹시 이미 대박 아이템을 찾아서 퇴사 욕구가 가득한가? 참아야
                  한다. 잘 알겠지만 시장 상황은 최악이다. 안정적인 급여를
                  받으면서 사이드 프로젝트로 시작하자. 당신을 눈여겨 본 VC나
                  부자 친구가 시드머니로 몇억을 쾌척하지 않는 이상 회사가
                  최고다. 궤도에 오른 후 퇴사해도 온갖 위험요소가 가득하다.{" "}
                  <strong>
                    매출이 없는데 버틸 자금마저 부족하다면 지옥불에 던져지는 것
                  </strong>
                  이다. 리스크는 차고 넘치니 최대한 줄여보자.
                </p>
                <br />

                <p>
                  스타트업은 엄청난 학벌과 경력을 지녀야만 성공하는 게임일까?
                  시장에 유동성이 넘치던 시절 "카이스트 전산학부 3명만 모여도
                  투자한다."라는 우스갯소리가 돌기도 했다. 그만큼 초기
                  단계에서는 평가할 지표가 많지 않고, 신뢰가 중요한 영역이니{" "}
                  <strong>좋은 학벌과 경력은 분명 도움이 된다.</strong> 여러분도
                  주식 투자에 볼린저 밴드부터 시작해서 쓸 수 있는 모든 지표를
                  동원하지 않던가.{" "}
                  <strong>
                    VC도 LP의 출자금을 바탕으로 높은 수익률에 사활을 거는 하나의
                    기업
                  </strong>
                  이다. 냉정하다고, 겨우 몇 번 까였다고 좌절하지 말자.
                </p>
                <br />

                <p>
                  너무 현실투성이라 주눅이 드는가? 아주 고맙게도 게임 공략법은
                  하나로 그치지 않는다. 알고 보면 우리가 열광하는 케이스는 정석
                  루트의 정반대 아닌가? 사이드 프로젝트로 시작해서 어떤 투자도
                  없이 수십억의 순이익을 올리는{" "}
                  <a
                    href="https://www.youtube.com/watch?v=Nlw4uVJgT7c"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    딜라이트룸
                  </a>
                  ,{" "}
                  <a
                    href="https://www.monymony.co/862e30c5-9465-4e24-a81d-0181543ca451"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    모니모니
                  </a>
                  의 사례가 많은 개발자의 이상향일 것이라 생각한다. 어떤 과정을
                  거쳤는지 <strong>무료로 배포된 양질의 자료와 정보</strong>를
                  참고하자.
                </p>
                <br />

                <p>
                  성공한 대표는 비법을 팔아 돈을 벌지 않는다. 우리에게 한 줄기
                  빛이 되어주던 인도 개발자들의 유튜브 영상처럼 조금만 노력하면
                  '진짜'들의 정보를 무료로 배울 수 있는 시대다. '가난은
                  정신병'이라며{" "}
                  <strong>
                    부를 과시하고, 노동을 천대하는 사기꾼들에게 제발 한 푼도
                    낭비하지 말자.
                  </strong>{" "}
                  그 돈으로 치킨을 뜯으며 기력을 보충하는 게 여러모로 유익할
                  것이다.
                </p>

                <blockquote>
                  <p>
                    출처: 혁신의숲 -{" "}
                    <a
                      href="https://www.innoforest.co.kr/company/CP00010443"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      딜라이트룸
                    </a>
                    ,{" "}
                    <a
                      href="https://www.innoforest.co.kr/company/CP00012139"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      모니모니
                    </a>{" "}
                    순
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/7ce869c3-8c1b-4564-a425-ec443d6faf12/image.png" />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/b585e17e-1fd5-47b7-851a-286f80774fbf/image.png" />
                </blockquote>
                <br />

                {/* [ 5 ] 너는 뭐 하고 있어? */}
                <h2>[ 5 ] 너는 뭐 하고 있어?</h2>
                <p>
                  '안전'을 혁신하겠다 외쳤지만 실패했고, '부동산'시장에서 기회를
                  발견했으며, '외주 개발'로 현금 흐름을 만든다.
                </p>
                <br />

                <p>
                  보안 시장은 미국이 선두주자다. 다양한 업체가 있지만 참고
                  모델로 두 서비스를 추렸다. 제조업 분야에서는 Amazon이 10억
                  달러에 인수한{" "}
                  <a
                    href="https://ring.com/"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Ring
                  </a>
                  , 모바일 앱은{" "}
                  <a
                    href="https://www.sequoiacap.com/"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Sequoia
                  </a>
                  의 투자를 받은{" "}
                  <a
                    href="https://citizen.com/"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Citizen
                  </a>
                  이다. 첫 진행 방향은 Citizen 모델에 가까웠으나 Sequoia에서
                  후속 투자를 포기할 만큼 수익성 개선에 답이 보이지 않았다. 결국
                  공공 안전 및 보안 시장에서 지속 가능한 혁신 모델을 만들고자
                  한다면 모바일에 제조업이 결합되어야 한다는 결론을 내렸다. 이
                  지점에서{" "}
                  <strong>
                    필수적인 자금 확보에 실패했고, 향후 생존을 위한 현금 흐름이
                    필요
                  </strong>
                  했다.
                </p>
                <br />

                <p>
                  지난 글을 통해 연락을 주신 분들로부터 사업 및 기술적인
                  측면에서 많은 조언과 도움을 받았다. 하지만 부끄럽고 죄송하게도
                  다시금 외주 개발이 필요한 상황에 이르렀다. 합류를 희망하지만
                  멀리 본다고, 당장 성공하지 못해도 괜찮다는 10년 차 선배님의
                  말씀이 정말 큰 위로가 됐다.{" "}
                  <strong>
                    아직 역량도 안 되면서 불의에 분노하거나 사명감에 휩싸이지
                    말고, 내 사람부터 잘 챙겨야겠다는 다짐
                  </strong>
                  을 했다. 유저를 모은 뒤 나중에 수익이 창출되는 비즈니스 말고,
                  당장 매출이 발생해서 자생 가능한 아이템을 시도하는 게 맞다고
                  되새겼다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.crunchbase.com/organization/citizen-d7ce/company_financials"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      Crunchbase
                    </a>
                    ,{" "}
                    <a
                      href="https://maily.so/ianpark/posts/dff64c5a"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      주간 실리콘밸리
                    </a>{" "}
                    순
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/1a7f6e1b-d728-4f81-a20c-eee2992de461/image.png" />
                  <img
                    src="https://velog.velcdn.com/images/kyleryu/post/fe5c1d5b-4534-4204-95dd-7f8fa1b97c49/image.png"
                    alt="보안시장"
                    style={{ width: "100%" }}
                  />
                </blockquote>
                <br />

                <p>
                  이후 가까운 분으로부터 재개발 사업과 관련된 시스템 개발 의뢰를
                  받았다. 이를 계기로{" "}
                  <strong>부동산 시장에서의 기회를 포착</strong>할 수 있었다.
                  복잡한 인증 과정을 뚫고 유입되는 수치와 다양한 활동 데이터,
                  그리고 추가적인 시장 조사를 통해 큰 불편과 욕망을 발견했다.
                </p>
                <br />

                <p>
                  아쉽지만 이 아이템도 빠른 수익 창출이 어렵다. 보안 영역과
                  대비하면 비용도 낮고, 자생 가능한 구조이지만 당장 주력으로
                  가져가기에는 리스크가 크다. 실패하지도, 포기하지도 않았지만
                  지금 올인하지는 말아야겠다는 판단을 내렸다.
                </p>

                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/c23ed060-06bc-4a48-92e8-cdb10c25fc8f/image.jpeg" />
                </blockquote>
                <br />

                <p>
                  이쯤 되면 외주 말고 정부 지원금을 받는 게 낫지 않냐고
                  궁금해하는 사람도 있을 것이다. 맞다, 지금까지 생존한 것도
                  국가의 도움 없이는 불가능했다. 대출 자금을 포함해서 지원받은
                  금액만 1억에 달한다. 하지만{" "}
                  <strong>
                    페이퍼워크에 주객이 전도되고, 고객이 아니라 사업계획서와
                    제품의 완성도에 몰두
                  </strong>
                  하는 내 모습이 너무 답답했다. 이러려고 사업을 시작한 건
                  아니잖나. 외주는 직접 벌기라도 하지, 정책 자금에 의존하다간
                  좀비기업이 될 것 같았다.
                </p>

                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/4d147007-16e0-48ed-9025-77cd6773cfa1/image.jpeg" />
                </blockquote>
                <br />

                <p>
                  솔직히 많이 지치기도 했고 성공이 간절하다.{" "}
                  <strong>큰 성공만 좇다가 말라죽겠다는 위기감</strong>이
                  들었다. 작은 성공이라도 괜찮으니 제대로 된 성취감이 필요했다.
                  작더라도 내가 잘하고 좋아하면서 사람들이 열망하는 것, 그게
                  뭘까. 많은 리스트 중 '1인 창업가'라는 주제가 눈에 꽂혔다.
                  그동안 생존을 위해 홀로 보낸 기간이 길었다.{" "}
                  <strong>1인 창업과 노코드에 관한 콘텐츠</strong>는 꾸준히 많은
                  관심을 받고 있다. 노코드의 특성 때문인지 디자인과 기획
                  직군에서 과감하게 시도하고 좋은 결과를 만들고 있었다. 그리고
                  역시나 개발자는 찾기 힘들었다.
                </p>
                <br />

                <p>
                  많은 개발자들은 빅테크를 목표로 한다. 물론 예외 사항도 있으나
                  일반적으로 해당 기업들은 시스템 복잡도를 낮추기 위해 고도의
                  분업화가 이뤄지고, 제너럴리스트가 아닌 스페셜리스트를 필요로
                  한다. {""}
                  <s>(제프 딘 같은 존재는 무시하자)</s>
                  {""} 따라서 학부생 시절, 취미 수준일 때는 폭넓게 학습하지만
                  커리어가 시작된 이후로는 한 분야를 집중적으로 공부해야 한다.
                  개발은 한 영역만 파더라도 깊게 들어갈수록 끝이 보이지 않고, 그
                  양이 방대하다. 이런 상황에서{" "}
                  <strong>
                    기술적 성장에 아무 도움이 안 된다고 여겨지는 노코드
                  </strong>
                  는 관심 목록에도 없는 게 어쩌면 당연하다.
                </p>
                <br />

                <p>
                  사업 마인드로 넘어가거나 시니어 레벨에 이르면, 전체를 관망하는
                  아키텍트의 영역에 진입하면 또 다르다. 그때부터는 다시금 폭넓게
                  수용할 여유가 생기는 것 같다. 우리 비즈니스에 적합한 도구라면
                  그게 어떤 기술이든 괜찮고, 심지어 노코드여도 상관없다는
                  것이다. 이런 모든 부분을 종합해{" "}
                  <strong>
                    개발자의 관점에서 노코드를 다루고, 1인 창업가의 성장 과정이
                    궁금한 이들을 대상으로 콘텐츠를 만들자
                  </strong>
                  는 생각이 들었다.
                </p>

                <blockquote>
                  <p>
                    <a
                      href="https://www.youtube.com/@soloventures_"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      솔로벤처스
                    </a>
                    라는 채널을 만들었다. 아직 콘텐츠는 없지만{" "}
                    <strong>
                      1인 창업이나 노코드에 관심이 있다면 구독을 추천
                    </strong>
                    한다.
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/c6cc59ae-9d4f-4bb1-b41a-a0e122eced26/image.png" />
                </blockquote>
                <br />

                {/* 맺음말 */}
                <h2>맺음말</h2>
                <p>
                  경제 상황이 좋지 않다. 기업이 어려우니 취업과 이직도 힘들
                  것이다. 사연 없는 사람이 어디 있겠는가. 가난을 팔아 돈을 벌
                  생각은 추호도 없다. 내가 어려운 시절을 겪었다고 힘든 이에게
                  조언을 건넬 자격이 생기는 것도 아니다. 그러나 정말
                  "힘내세요!"라고 말해주고 싶다.
                </p>
                <br />
                <p>
                  너무 힘든 상황이라 도저히 개발을 할 여력이 없는 사람도 있을
                  것이다. 포기하는 사례가 많이 보인다. 나 또한 도전할 기회와
                  여유조차 없어서 몸만 고생하던 시절이 있었다.{" "}
                  <strong>
                    지금의 상황은 당신의 잘못이 아니라고, 분명 다시 기회가 올
                    거라고
                  </strong>{" "}
                  격려하고 싶다.
                </p>

                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/4ad0a046-3063-4ed7-a35b-ec7ed0c20170/image.png" />
                  <img
                    src="https://velog.velcdn.com/images/kyleryu/post/632822d3-e08e-41ee-bf7c-351fa6a00122/image.png"
                    alt="택배 상하차"
                    style={{ width: "100%" }}
                  />
                </blockquote>
                <br />

                <p>
                  나도 몇 번이고 죽고 싶었다. 그때마다 "살아야 할 이유가 있다면
                  제발 버틸 힘만이라도 주세요."라며 울면서 기도했다. 매일 밤마다
                  상황을 타개할 수 없는 무력함을 원망하며 잠들었다. 그렇게
                  처지를 비관하면서도 실낱같은 희망과 꿈을 붙잡고 한 걸음씩 걷다
                  보니 지금까지 살아남았다.
                </p>
                <br />

                <p>
                  정말 간절하다면 악착같이 버텨서 꿈을 이뤄내길 바란다.{" "}
                  <strong>
                    세상은 불공평하다. 시련은 몰아서 온다. 온갖 억지를 부리는
                    역경을 다 박살 내고 반드시 성공에 이르길
                  </strong>{" "}
                  진심으로 응원한다.
                </p>

                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://www.youtube.com/watch?v=IFGdVij7zcs"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      비긴어게인
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/f46f7899-2a76-4422-ab6d-bf73393ceaa2/image.png" />
                </blockquote>
                <br />

                <br />
                <p>
                  추가 발행:
                  <br />
                  <a
                    href="https://velog.io/@kyleryu/%EA%B0%9C%EB%B0%9C%EC%9E%90%EA%B0%80-100%EC%96%B5%EC%9D%84-%EB%B2%84%EB%8A%94-%EA%B0%80%EC%9E%A5-%EB%B9%A0%EB%A5%B8-%EB%B0%A9%EB%B2%95"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Velog
                  </a>
                  <br />
                  <a
                    href="https://eopla.net/magazines/12493"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    EO Planet
                  </a>
                  <br />
                  <a
                    href="https://dis.qa/FEd"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    Disquiet
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
