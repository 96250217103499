import Nav from "../../components/Nav";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <main class="min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div class="text-center">
          <p class="text-base font-semibold text-orange-500">404</p>
          <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p class="mt-6 text-base leading-7 text-gray-600">
            죄송합니다. 찾으시려는 페이지는 존재하지 않습니다.
          </p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              class="rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              onClick={() => {
                navigate("/");
              }}
            >
              홈으로 가기
            </a>
            <a
              href="#"
              class="text-sm font-semibold text-gray-900 hover:text-orange-500"
              onClick={() => {
                navigate(-1);
              }}
            >
              뒤로 가기<span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
