import Nav from "../../../components/Nav";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet-async";
import styles from "./Post.module.css";

export default function Post() {
  return (
    <>
      <Helmet>
        <title>개발자가 1분 만에 네이버 만드는 방법 - 류용택(Kyle Ryu)</title>

        <meta
          property="og:image"
          content="https://velog.velcdn.com/images/kyleryu/post/44773ff5-6112-495b-8902-1094fb1cc0ac/image.png"
        />
        <meta
          property="og:description"
          content="어떤 서비스든 1분이면 충분합니다."
        />
        <meta
          property="og:title"
          content="개발자가 1분 만에 네이버 만드는 방법 - 류용택(Kyle Ryu)"
        />
      </Helmet>
      <Nav />
      <div className={styles.mainDiv}>
        <div
          className={`${styles["sc-jObWnj"]} ${styles["sc-dPiLbb"]} ${styles.cMpExe}`}
        >
          <div className={styles["sc-efQSVx"]} />
          <div
            className={`${styles["sc-dvQaRk"]} ${styles.ckXmXH} ${styles["sc-iFMAIt"]} ${styles.hvSIqH}`}
          >
            <div className={styles["head-wrapper"]}>
              <h1>개발자가 1분 만에 네이버 만드는 방법</h1>
              <div className={styles["sc-iqVWFU"]}>
                <div className={styles.information}>
                  <span>2024년 04월 02일</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles["sc-eldieg"]} ${styles.esFXbZ}`}>
            <div className={`${styles["sc-bQtKYq"]} ${styles.fZOLYn}`}>
              <div
                className={`${styles["sc-fXEqDS"]} ${styles.kLqDvr} ${styles["atom-one"]}`}
              >
                {/* 글 시작 */}
                <p>어떤 서비스든 1분이면 충분합니다.</p>
                <br />
                <blockquote>
                  <p>
                    <a
                      href="https://youtu.be/8W5IxEwO4Us?feature=shared"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      영상으로 확인하기
                    </a>
                    <br />
                    <img src="https://velog.velcdn.com/images/kyleryu/post/44773ff5-6112-495b-8902-1094fb1cc0ac/image.png" />
                  </p>
                </blockquote>
                <br />

                <br />
                {/* [ 1 ] 서비스 카피 */}
                <h2>[ 1 ] 서비스 카피</h2>

                <p>
                  기존 서비스를 카피하는 방법은 다양합니다. 우리는 그중에서
                  누구나 따라 할 수 있는 방법을 알아볼 겁니다.
                </p>
                <br />
                <p>
                  먼저{" "}
                  <a
                    href="https://chromewebstore.google.com/detail/css-used/cdopjfddjlonogibjahpnmjpoangjfff"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    'CSS Used'
                  </a>
                  라는 확장 프로그램과{" "}
                  <a
                    href="https://code.visualstudio.com/"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    'Visual Studio Code'
                  </a>
                  같은 텍스트 에디터를 설치합니다. 이제 개발자 도구를 열어서
                  body 부분을 복사합니다. 그리고 우리가 설치한 CSS Used로 html
                  태그를 클릭한 뒤 전체 css를 카피합니다. link 태그로 css 파일을
                  연결한 다음 디버깅 버튼을 눌러서 웹을 띄워봅니다.
                </p>
                <br />
                <p>
                  자세한 설명은{" "}
                  <a
                    href="https://youtu.be/8W5IxEwO4Us?feature=shared"
                    target="_blank"
                    style={{ color: "#ea580c" }}
                  >
                    영상에서 확인
                  </a>
                  해 주세요!
                </p>
                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/7c505a6f-3c0a-421b-bff9-a938621805cb/image.png" />
                </blockquote>
                <br />
                {/* [ 2 ] 범죄 주의 */}
                <h2>[ 2 ] 범죄 주의</h2>
                <p>
                  우리가 직접 해봤듯이 기존 서비스의 외관을 그대로 베끼는 건
                  어려운 기술이 아닙니다. 게임을 복제해서 불법 서버를 운영하는
                  사례, 앱을 그대로 카피해서 사기 범죄에 악용하는 경우 등 온갖
                  범죄가 넘쳐납니다.
                  <br />
                  <br />
                  그러니까 대형 서비스와 유사하다고 신뢰해서는 안 됩니다. 모르는
                  사람이 보낸 이메일이나 문자에 첨부된 링크는 애초에 클릭하지
                  않는 게 안전합니다.
                </p>
                <br />
                <blockquote>
                  <p>
                    출처:{" "}
                    <a
                      href="https://news.sbs.co.kr/news/endPage.do?news_id=N1007580284"
                      target="_blank"
                      style={{ color: "#ea580c" }}
                    >
                      가짜 틱톡 앱 깔고 "1억 날렸다"
                    </a>
                  </p>
                  <br />
                  <img src="https://velog.velcdn.com/images/kyleryu/post/2422d8dc-bdd8-4734-a958-bc476dbc1941/image.png" />
                </blockquote>
                <br />

                {/* [ 3 ] UI/UX 디자인 */}
                <h2>[ 3 ] UI/UX 디자인</h2>
                <p>
                  해킹 같은 범죄가 아니더라도 이대로 디자인을 갖다 쓰면
                  도용이죠. 다음 영상에서는 벤치마킹은 어떻게 하는 건지, 웹과 앱
                  디자인을 어떻게 하면 좋을지 알아보겠습니다.
                </p>
                <br />

                <blockquote>
                  <img src="https://velog.velcdn.com/images/kyleryu/post/831b6dc5-3eaf-4aa8-ac7d-304f52edfbbd/image.png" />
                </blockquote>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
