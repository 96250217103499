import styles from "./PostList.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import ViewAll from "./ViewAll";
import ViewAllDev from "./ViewAllDev";

export default function PostListDev() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className={styles.info}>
        <section className={styles.css_1xw6rpm}>
          {/* 상단 타이틀 */}
          <div className={styles.css_1ixgpnt}>
            <h2 className={styles.css_1elspdf}>개발</h2>
            {/* 전체보기 */}
            {/* <div className={styles.css_fl7us7}>
              <a>
                <div className={styles.css_p1vanc}>
                  <p
                    className={styles.css_byjqmz}
                    onClick={() => {
                      navigate("/prolisten");
                    }}
                  >
                    전체보기
                  </p>
                  <span>
                    <div className={styles.css_1gvano}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="#535961"
                          d="M9.33 4.83c.439-.44 1.151-.44 1.59 0l6.375 6.374c.44.44.44 1.152 0 1.591L10.92 19.17a1.125 1.125 0 1 1-1.59-1.59L14.909 12l-5.58-5.58a1.125 1.125 0 0 1 0-1.59z"
                        ></path>
                      </svg>
                    </div>
                  </span>
                </div>
              </a>
            </div> */}
            <ViewAllDev />
          </div>

          <ul className={styles.css_1nwykk}>
            {/* 포스팅 목록 => li */}
            {/* 네이버 */}
            <li
              onClick={() => {
                navigate("/develop/1");
              }}
            >
              <article className={styles.css_1pr9edg}>
                <a>
                  <figure className={styles.css_1gd0osy}>
                    <img
                      alt="썸네일"
                      sizes="100vw"
                      src="https://firebasestorage.googleapis.com/v0/b/kyleryu-blog.appspot.com/o/home%2Fnaver.webp?alt=media&token=62100163-660e-4707-95f2-ca951685eeeb"
                      decoding="async"
                      data-nimg="future-fill"
                      loading="lazy"
                    />
                  </figure>
                  <div className={styles.wrapBox}>
                    <div>
                      <div role="button" tabindex="0">
                        <h3
                          className={[styles.userName, styles.css_1to8sx0].join(
                            " "
                          )}
                        >
                          개발자가 1분 만에 네이버 만드는 방법
                        </h3>
                      </div>
                    </div>
                    <div className={styles.css_1rtzn1u}>
                      <span>
                        <p className={styles.css_oz575p}>2024년 04월 02일</p>
                      </span>
                    </div>
                  </div>
                  <div className={styles.css_16nb092}></div>
                </a>
              </article>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}
