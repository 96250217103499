import logo from "../images/logo.svg";
import styles from "./Nav.module.css";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

// SC에도 Tailwind 사용 가능하게 함 => 여기서는 아직 미사용
// import tw from "tailwind-styled-components";

let NavIcon = styled.div`
  position: relative;
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: ${(props) => props.ml}px;
  cursor: pointer;
`;

let Menu = styled.a`
  cursor: pointer;
  font-size: ${(props) => props.fs}px;
  font-weight: 600;
  color: ${(props) => (props.active ? "rgb(234, 88, 12)" : "rgb(17, 24, 39)")};
  &:hover {
    color: rgb(234, 88, 12);
  }
`;

export default function Nav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();

  return (
    <>
      {/* 데스크탑 Nav */}
      <header className={styles.header}>
        <nav className={styles.first_nav}>
          <div className={styles.left_GNB}>
            <div className="flex">
              {/* 로고 */}
              <a
                className={styles.logo}
                onClick={() => {
                  navigate("/");
                }}
              >
                {/* 이미지 태그 끝에 / 필수 */}
                <img
                  alt="logoImage"
                  src={logo}
                  className="cursor-pointer h-24pxr w-96pxr"
                />
              </a>
              {/* 메뉴 - mobile first - 320px부터 기본이 hidden 설정 - md값인 768px부터 활성화 */}
              <div className="ml-6 items-center space-x-7 hidden md:flex lg:ml-[50px]">
                {/* shrink-0도 Tailwind를 통해 형태가 유지되는 중요 속성 */}
                {/* 홈 */}
                <div className="shrink-0">
                  <Menu
                    fs="18"
                    active={location.pathname === "/"}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    홈
                  </Menu>
                </div>
                {/* 사업 */}
                <div className="shrink-0">
                  <Menu
                    fs="18"
                    active={location.pathname.startsWith("/business")}
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    사업
                  </Menu>
                </div>
                {/* 개발 */}
                <div className="shrink-0">
                  <Menu
                    fs="18"
                    active={location.pathname.startsWith("/develop")}
                    onClick={() => {
                      navigate("/develop");
                    }}
                  >
                    개발
                  </Menu>
                </div>
                {/* 디자인 */}
                {/* <div className="shrink-0">
                  <Menu
                    fs="18"
                    active={location.pathname.startsWith("/design")}
                    onClick={() => {
                      navigate("/design");
                    }}
                  >
                    디자인
                  </Menu>
                </div> */}
                {/* 솔로벤처스 */}
                <div className="shrink-0">
                  <Menu
                    fs="18"
                    onClick={() => {
                      window.location.href = "https://soloventures.net";
                    }}
                  >
                    솔로벤처스
                  </Menu>
                </div>
                {/* 링크드인 */}
                <div className="shrink-0">
                  <Menu
                    fs="18"
                    onClick={() => {
                      window.location.href =
                        "https://www.linkedin.com/in/kyle-ryu-381b5b227/";
                      // window.open(
                      //   "https://www.linkedin.com/in/kyle-ryu-381b5b227/",
                      //   "_blank"
                      // );
                    }}
                  >
                    링크드인
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {/* 채팅, 찜, 프로필 */}
          <div className={styles.right_GNB}>
            {/* <NavIcon ml="12">
              <a
                onClick={() => {
                  window.location.href =
                    "https://buy.stripe.com/14kg0FgMOcbK8bCdQQ";
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 md:w-7 md:h-7 hover:fill-slate-100"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                  />
                </svg>
              </a>
            </NavIcon> */}
            {/* <NavIcon ml="20">
              <a
                onClick={() => {
                  user ? navigate("/likes") : navigate("/login");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 md:w-7 md:h-7 hover:fill-black"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                  />
                </svg>
              </a>
            </NavIcon>
            <NavIcon ml="20">
              <a
                onClick={() => {
                  user ? navigate("/profile") : navigate("/login");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 md:w-7 md:h-7 hover:fill-black"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              </a>
            </NavIcon> */}
          </div>
          {/* right-GNB 끝 */}
        </nav>
        <div className={`${styles.header_bottom} ${styles.hb1}`}></div>
      </header>
      {/* 모바일 Nav */}
      <div className={styles.second_header}>
        <nav className={styles.second_nav}>
          <ul className="flex space-x-7">
            {/* 홈 */}
            <li>
              <Menu
                fs="16"
                active={location.pathname === "/"}
                onClick={() => {
                  navigate("/");
                }}
              >
                홈
              </Menu>
            </li>
            <li>
              <Menu
                fs="16"
                active={location.pathname.startsWith("/business")}
                onClick={() => {
                  navigate("/business");
                }}
              >
                사업
              </Menu>
            </li>
            <li>
              <Menu
                fs="16"
                active={location.pathname.startsWith("/develop")}
                onClick={() => {
                  navigate("/develop");
                }}
              >
                개발
              </Menu>
            </li>
            {/* <li>
              <Menu
                fs="16"
                active={location.pathname.startsWith("/design")}
                onClick={() => {
                  navigate("/design");
                }}
              >
                디자인
              </Menu>
            </li> */}
            <li>
              <Menu
                fs="16"
                // active={location.pathname.startsWith("/daily")}
                onClick={() => {
                  // navigate("/daily");
                  window.location.href = "https://soloventures.net";
                  // window.open(
                  //   "https://www.linkedin.com/in/kyle-ryu-381b5b227/",
                  //   "_blank"
                  // );
                }}
              >
                솔로벤처스
              </Menu>
            </li>
            <li>
              <Menu
                fs="16"
                // active={location.pathname.startsWith("/daily")}
                onClick={() => {
                  // navigate("/daily");
                  window.location.href =
                    "https://www.linkedin.com/in/kyle-ryu-381b5b227/";
                  // window.open(
                  //   "https://www.linkedin.com/in/kyle-ryu-381b5b227/",
                  //   "_blank"
                  // );
                }}
              >
                링크드인
              </Menu>
            </li>
          </ul>
        </nav>
        <div className={`${styles.header_bottom} ${styles.hb2}`}></div>
      </div>
    </>
  );
}
