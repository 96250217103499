import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';

export default function Service() {
  return (
    <>
      <Helmet>
        <title>이용약관 - 프로과외</title>
      </Helmet>
      <Nav />
      <div>이용약관</div>
      <Footer />
    </>
  )
}