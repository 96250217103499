import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import NoContent from "../../components/NoContent";
import PostListDev from "../../components/PostListDev";

export default function Develop() {
  return (
    <>
      <Helmet>
        <title>개발 - 류용택(Kyle Ryu)</title>
      </Helmet>
      <Nav />
      <PostListDev />
      <Footer />
    </>
  );
}
