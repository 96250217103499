import styles from "./Login.module.css";
import google from "../../images/google.svg";
import kakao from "../../images/kakao.svg";
import naver from "../../images/naver.svg";
import { useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLogin } from "../../hooks/useLogin";
import styled from "styled-components";

const Main = styled.main`
  text-align: left;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
`;

export default function Login() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { error, isPending, login, loginWithGoogle } = useLogin();

  const handleData = (event) => {
    if (event.target.type === "email") {
      setEmail(event.target.value);
    } else if (event.target.type === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(email, password);
  };

  return (
    <>
      <Helmet>
        <title>로그인 - 류용택(Kyle Ryu)</title>
      </Helmet>
      <Nav />
      <Main>
        {/* 로그인 폼 */}
        <div className="px-12 mt-14 mb-14">
          <h2 className="-mb-3 text-2xl font-semibold text-center text-black font-display md:text-3xl">
            로그인
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit}>
              {/* 이메일 */}
              <div>
                <div className="text-sm font-bold tracking-wide text-gray-700 md:text-base">
                  이메일 주소
                </div>
                <input
                  id="email"
                  className="w-full py-2 text-lg border-b border-gray-300 focus:outline-none focus:border-orange-500"
                  type="email"
                  placeholder="이메일 주소 입력"
                  required
                  value={email}
                  onChange={handleData}
                />
              </div>
              {/* 비밀번호 */}
              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-bold tracking-wide text-gray-700 md:text-base">
                    비밀번호{" "}
                  </div>
                </div>
                <input
                  id="pw"
                  className="w-full py-2 text-lg border-b border-gray-300 focus:outline-none focus:border-orange-500"
                  type="password"
                  placeholder="비밀번호 입력"
                  required
                  minlength="6"
                  value={password}
                  onChange={handleData}
                />
              </div>
              {/* 로그인 */}
              <div className="mt-10">
                <button
                  id="login"
                  className="w-full p-3 mt-0 text-base font-semibold tracking-wide text-gray-100 bg-black rounded shadow-lg font-display focus:outline-none focus:shadow-outline hover:bg-zinc-800 md:text-lg"
                  type="submit"
                >
                  로그인
                </button>
              </div>
            </form>
            {/* 간편 로그인 시작 */}
            <div className={styles.sign_in}>
              <hr className={styles.sign_line} />
              <span className={styles.sign_title}>간편 로그인</span>
              <div className={styles.sign_button}>
                <button
                  onClick={(e) => {
                    // 카카오톡 브라우저인지 체크하는 코드
                    const isKakaoTalkBrowser = /kakaotalk/.test(
                      navigator.userAgent.toLowerCase()
                    );

                    if (isKakaoTalkBrowser) {
                      // 카카오톡 브라우저일 경우
                      alert(
                        "카카오톡에서는 구글 로그인을 사용할 수 없습니다. \n 다른 브라우저(크롬, 사파리)를 이용해 주세요."
                      );
                      e.preventDefault(); // 클릭 이벤트를 중단시킵니다.
                    } else {
                      // 카카오톡 브라우저가 아닐 경우, 기존의 로그인 함수를 실행합니다.
                      loginWithGoogle();
                    }
                  }}
                  className={styles.social_button}
                  data-provider="Google"
                  data-link="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&amp;prompt=consent&amp;scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&amp;response_type=code&amp;client_id=887875630717-ror9t8ig4obhvokdij07eoochpqbu5kf.apps.googleusercontent.com&amp;redirect_uri=https%3A%2F%2Fwww.inflearn.com%2Fauth%2Fgoogle&amp;state=%7B%22prev_url%22%3A%22https%3A%2F%2Fwww.inflearn.com%2Fsignin%3FreferUrl%3Dhttps%253A%252F%252Fwww.inflearn.com%252F%22%7D"
                >
                  <img src={google} alt="구글 로그인" />
                </button>
                {/* <button className={styles.social_button} data-provider="Kakao"
                  data-link="https://kauth.kakao.com/oauth/authorize?response_type=code&amp;client_id=0de1184063abadc9cef9dbf417c567a5&amp;redirect_uri=https%3A%2F%2Fwww.inflearn.com%2Fauth%2Fkakao&amp;scope=profile%2Caccount_email%2Cbirthday%2Cbirthyear%2Cphone_number%2Cage_range%2Cgender&amp;state=%7B%22prev_url%22%3A%22https%3A%2F%2Fwww.inflearn.com%2Fsignin%3FreferUrl%3Dhttps%253A%252F%252Fwww.inflearn.com%252F%22%7D">
                  <img src={kakao} alt="카카오 로그인" />
                </button>
                <button className={styles.social_button} data-provider="Naver"
                  data-link="https://kauth.kakao.com/oauth/authorize?response_type=code&amp;client_id=0de1184063abadc9cef9dbf417c567a5&amp;redirect_uri=https%3A%2F%2Fwww.inflearn.com%2Fauth%2Fkakao&amp;scope=profile%2Caccount_email%2Cbirthday%2Cbirthyear%2Cphone_number%2Cage_range%2Cgender&amp;state=%7B%22prev_url%22%3A%22https%3A%2F%2Fwww.inflearn.com%2Fsignin%3FreferUrl%3Dhttps%253A%252F%252Fwww.inflearn.com%252F%22%7D">
                  <img src={naver} alt="네이버 로그인" />
                </button> */}
              </div>
            </div>
            {/* 간편 로그인 끝 */}
            <div className="text-sm font-semibold text-center text-gray-700 font-display mt-7 md:text-base">
              아직 계정이 없으신가요?
              <a
                className="mt-2 text-orange-600 cursor-pointer hover:text-orange-400"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                가입하기
              </a>
            </div>
          </div>
        </div>
        {/* 로그인 폼 종료 */}
      </Main>
    </>
  );
}
