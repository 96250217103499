import styles from "./PostList.module.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function ViewAll() {
  const navigate = useNavigate();
  const location = useLocation();

  // '/business' 페이지에서는 '전체보기' 부분을 보여주지 않습니다.
  if (location.pathname === "/business" || location.pathname === "/develop") {
    return null;
  }

  return (
    <>
      {/* 전체보기 */}
      <div className={styles.css_fl7us7}>
        <a>
          <div className={styles.css_p1vanc}>
            <p
              className={styles.css_byjqmz}
              onClick={() => {
                navigate("/business");
              }}
            >
              전체보기
            </p>
            <span>
              <div className={styles.css_1gvano}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#535961"
                    d="M9.33 4.83c.439-.44 1.151-.44 1.59 0l6.375 6.374c.44.44.44 1.152 0 1.591L10.92 19.17a1.125 1.125 0 1 1-1.59-1.59L14.909 12l-5.58-5.58a1.125 1.125 0 0 1 0-1.59z"
                  ></path>
                </svg>
              </div>
            </span>
          </div>
        </a>
      </div>
    </>
  );
}
